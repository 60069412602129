import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'


class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
          current: '',
          new: '',
          retype: '',
          message: 'Are you sure to change password?',
          confirm: true
        };
      }

      alertMessage = () => {
        let buttons = []

        if(this.state.confirm){
            buttons = [
                {
                  label: 'Yes',
                  onClick: () =>  this.updatePassword()
                },
                {
                  label: 'No',
                  onClick: () => {}
                }
              ]
        } else {
            buttons = [
                {
                  label: 'Ok',
                  onClick: () => { 
                      this.setState({confirm: true, message: 'Are you sure to change password?'})
                     }
                }
              ]
        }
        confirmAlert({
          title: 'Confirm',
          message: this.state.message,
          buttons: buttons
        })
      };

      updatePassword = () => {
        let newPassword = this.state.new
        this.setState({confirm: false})
        this.reauthenticate()
        .then(() => {
            var user = firebase.auth().currentUser;
            user.updatePassword(newPassword).then(() => {
                this.setState({message: 'The password was updated successfully'})
                this.alertMessage()
            }).catch((err) => {
                this.setState({message: 'An error occurred updating the password'})
                this.alertMessage()
            });

        }).catch((err) => {
            this.setState({message: 'The password is invalid'})
            this.alertMessage()
        });
      }

      reauthenticate = () => {
        var user = firebase.auth().currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(
            localStorage.getItem('email'), this.state.current);
        return user.reauthenticateWithCredential(cred);
      }

      onChangeCurrent = (e) => {
        const value = e.target.value;
        this.setState({current:value});
      }

      onChangeNew = (e) => {
        const value = e.target.value;
        this.setState({new:value});
      }

      onChangeRetype = (e) => {
        const value = e.target.value;
        this.setState({retype:value});
      }

    render() {
      return (
        <div className="profile-details">
                <h2>Change password</h2>
                <div className="content-change-password">
                <div class="embed-input">
                  <h3>Current Password</h3>
                  <input type="password" name="current" id="current" onChange={(e) => this.onChangeCurrent(e)} placeholder="Current password" value={this.state.current}/></div>
                <div class="embed-input">
                  <h3>Nuevo Password</h3>
                  <input type="password" name="new" id="new" onChange={(e) => this.onChangeNew(e)} placeholder="New password" value={this.state.new}/></div>
                <div class="embed-input">
                  <h3>Confirm New Password</h3>
                  <input type="password" name="retype" id="retype" onChange={(e) => this.onChangeRetype(e)} placeholder="Confirm new password" value={this.state.retype} /></div>
                </div>
                <button disabled={(this.state.new != '' && this.state.retype != '' && this.state.new == this.state.retype)? false: true} className="btn-update-password" onClick={this.alertMessage} >Update password</button>
        </div>
      );
    }
  } 

export default ChangePassword;