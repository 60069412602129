/*global chrome*/
import React from 'react';
import Web from './Components/Web';
import {BrowserRouter, Switch, Route } from "react-router-dom"
import Login from './Components/Login';
import Signup from './Components/Signup';
import  WebShared from './Component-shared/WebShared';
import Home from './Components/Home';
import VerifyUser from './Components/VerifyUser';
import Pricing from './Components/Pricing';
import Cookies from './Components/Cookies';
import PrivacyPolicy from './Components/PrivacyPolicy';
import "../node_modules/video-react/dist/video-react.css";
import PaypalPartner from './Components/PaypalPartner';
import Success from './Components/Success';
import Cancel from './Components/Cancel';

function App() {
  return (

    <BrowserRouter> {/* envolvemos nuestra aplicación en el Router  */}
      <Switch> {/* también la envolvemos en el componente Switch */}
        <Route path="/home" component={Home} exact /> {/* y creamos nuestras rutas */}
        <Route path="/" component={Web} exact /> {/* y creamos nuestras rutas */}
        <Route path="/login" component={Login} />
        <Route path="/signup/:page" component={Signup} />
        <Route path="/signup" component={Signup} />
        <Route path="/shared/:page" component={WebShared} />
        <Route path="/verify" component={VerifyUser} />
        <Route path="/:uid/:page" component={Web} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/cookies" component={Cookies} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/paypal-partner" component={PaypalPartner} />
        <Route path="/success" component={Success} />
        <Route path="/canceled" component={Cancel} />
      </Switch>
      </BrowserRouter>
  );
}

export default App;
