import React from 'react';
class Image extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            show:false
        };
    }

    showIcon() {
        this.setState({show:true});
    };
    
      hideIcon() {
        this.setState({show:false});
    };

    showOption = (e) =>{
        e.stopPropagation();
        console.log("hey");
        this.setState({showOption:true});
    }


    removeItem = (e) =>{
        e.stopPropagation();
        this.setState({showOption:false});
        this.props.remove(this.props.index);
    }

    duplicateItem = (e) =>{
        e.stopPropagation();
        this.setState({showOption:false});
        this.props.duplicate(this.props.index);
    }



    render(){
        return(
            <div className="element-editor">
               <div className="remove">
                </div>
                <div className="imagen-element">
                    <iframe className="preview-image" width="100%" height="400" src={this.props.content}>
                    </iframe>
                </div>
            </div>
        );
    }

}




export default Image;