import React, { Component } from 'react';
import withFirebaseAuth from 'react-with-firebase-auth'
import * as firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import Icon from '@mdi/react';
import { mdiGoogle  } from '@mdi/js';
import {firebaseApp} from './Firebase'; 




class Signup extends Component {

  constructor(props){
    super(props); 

    this.state = {
      page: '',
      pageId: ''
    };
  }

  handleForm = (e)  => {
    e.preventDefault();

    firebase
      .auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(res => {
        console.log(res); 
        
      })
      .catch(e => {
        console.log(e);
      });
  };

  onChangeEmail = (e) => {
    const value = e.target.value;
    this.setState({email:value});
  }

  onChangePassword = (e) => {
    const value = e.target.value;
    this.setState({password:value});
  }

  componentDidMount(){
    var jwt = require('jwt-simple');
    if(this.props.match.params.page){
      var decoded = jwt.decode(this.props.match.params.page, 'copack');
      console.log(decoded)
      const dir = decoded.dir.split("/");       
      console.log(dir) 
      this.setState({
          pageId: dir[1]
      })
      console.log(this.state)
    }
  }

    render() {


      const {
        user,
        signOut,
        signInWithGoogle,
      } = this.props;

      if(user){

        let params = {
          'uid': user.uid,
          'email': user.email
       };
        if(this.state.pageId){
          params.page = this.state.pageId
        }

        axios.post(process.env.REACT_APP_API_HOST+"sign", params, { // receive two parameter endpoint url ,form data 
          
            headers: {
                'content-type': 'application/json',
            },
      
        })
        .then(res => {

            if(res.statusText==="OK"){
              
                const userCreated = res.data;

                if(!user.emailVerified){

                  firebase.auth().currentUser.sendEmailVerification().then(function() {
                         alert('Your account needs to be verified, an email has been sent to your account '+user.email);
                        }).catch(function(error) {
                        // An error happened.
                      });
                }else{
                
                  if(userCreated){
                      localStorage.setItem('uid', user.uid);
                      localStorage.setItem('email', user.email);
                      if(this.props.match.params.page){
                        this.props.history.push('/shared/' + this.props.match.params.page);
                      }else{
                        this.props.history.push('/');
                      }
                      
                  }

                }
            }

        })

       
          
      }


      return (
        <div className="login-container">
          <div className="login">
              {
                <p>Register</p>
              }
              <div className="body-form-login">
              <div className="form-login-with-email">
                <label>Email</label>
                <input type="text" name="correo" placeholder="Email" onChange={(e) => this.onChangeEmail(e)} ></input>
                <label>Password</label>
                <input type="password" name="password" placeholder="Password" onChange={(e) => this.onChangePassword(e)}></input> 
                <button onClick={this.handleForm}> Sign up</button>
              
              </div>
              </div>
              { 
              <div className="btn-body"><button className="btn-gmail" onClick={signInWithGoogle}>
                   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.1713 8.36873H17.5V8.33415H10V11.6675H14.7096C14.0225 13.6079 12.1763 15.0008 10 15.0008C7.23877 15.0008 5.00002 12.7621 5.00002 10.0008C5.00002 7.23956 7.23877 5.00081 10 5.00081C11.2746 5.00081 12.4342 5.48165 13.3171 6.26706L15.6742 3.90998C14.1859 2.5229 12.195 1.66748 10 1.66748C5.39794 1.66748 1.66669 5.39873 1.66669 10.0008C1.66669 14.6029 5.39794 18.3341 10 18.3341C14.6021 18.3341 18.3334 14.6029 18.3334 10.0008C18.3334 9.44206 18.2759 8.89665 18.1713 8.36873Z" fill="#FFC107"/>
                  <path d="M2.62756 6.12206L5.36548 8.12998C6.10631 6.29581 7.90048 5.00081 10.0001 5.00081C11.2746 5.00081 12.4342 5.48165 13.3171 6.26706L15.6742 3.90998C14.1859 2.5229 12.1951 1.66748 10.0001 1.66748C6.79923 1.66748 4.0234 3.47456 2.62756 6.12206Z" fill="#FF3D00"/>
                  <path d="M9.99999 18.3332C12.1525 18.3332 14.1083 17.5094 15.5871 16.1699L13.0079 13.9874C12.1431 14.645 11.0864 15.0007 9.99999 14.9999C7.83249 14.9999 5.99207 13.6178 5.29874 11.689L2.58124 13.7828C3.9604 16.4815 6.76124 18.3332 9.99999 18.3332Z" fill="#4CAF50"/>
                  <path d="M18.1713 8.3671H17.5V8.33252H10V11.6659H14.7096C14.3809 12.5894 13.7889 13.3963 13.0067 13.9871L13.0079 13.9863L15.5871 16.1688C15.4046 16.3346 18.3333 14.1659 18.3333 9.99919C18.3333 9.44044 18.2758 8.89502 18.1713 8.3671Z" fill="#1976D2"/>
                  </svg>
                    <span>Register with Google</span>
                    </button></div>
              }
          </div>
        </div>
            
      );
    }
  } 

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(Signup);