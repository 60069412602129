import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';




class VerifyUser extends Component {

  constructor(props){
    super(props); 

    this.state = {
      error:false
    };
  }




    render() {

    
      return (
        <div>
          <div className='verify-user'>
              <img src={require("../Assets/error.png")}></img>
              <span>The payment not was made correctly <a href="/pricing">Try again here</a></span>
          </div>
        </div>
      );
    }
  } 

export default VerifyUser;