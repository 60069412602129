
import React from 'react';
import axios from 'axios';

class ListElementWithText extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            elements : [
                {

                    name : 'Record video',
                    icon : 'grabarscreen.png',

                },
                {

                    name : 'Record Video + Cam',
                    icon : 'grabarwecam.png',

                },
                {

                    name : 'Text',
                    icon : 'text.png',

                },
                {

                    name : 'Header',
                    icon : 'h1.png',

                },
                {

                    name : 'Subheader',
                    icon : 'h2.png',

                },
                {

                    name : 'Image',
                    icon : 'imagen.png',

                },
                {

                    name : 'Youtube',
                    icon : 'Youtube.png',

                },
                {

                    name : 'Google Drive',
                    icon : 'docs.png',

                },
                {

                    name : 'File',
                    icon : 'file.png',

                },
                {

                    name : 'Embed Link',
                    icon : 'imagen.png',

                },
                {

                    name : 'Divider',
                    icon : 'divider.png',

                },
                {

                    name : 'Video',
                    icon : 'subirvideo.png',

                }
                ,
                {

                    name : 'Embed Code',
                    icon : 'embed.png',

                }
                ,
                {

                    name : 'Typeform',
                    icon : 'typeform_icon.png',

                }
            ],

            textFilter : '',
            premium : true,
        }

    }

    componentDidMount = () =>{

        const data ={
            uid : localStorage.getItem('uid')
        }

        axios.post(process.env.REACT_APP_API_HOST+'restrictions',data)
        .then(restrictions => {


            if(restrictions.data){

                this.setState({
                    premium:false
                })

            }

        });

    }


    selectList = (e,value) =>{
        e.stopPropagation();
        const data ={
            uid : localStorage.getItem('uid')
        }
        axios.post(process.env.REACT_APP_API_HOST+'restrictions',data)
        .then(restrictions => {
            if(!restrictions.data){
                this.props.selectItemChild(value,this.props.index);
                this.props.hidenElement();
            }else{
                if(value.name == 'Record Video + Cam'){
                    window.open("https://app.copack.io/pricing", "_blank");
                    return;
                }
                axios.post(process.env.REACT_APP_API_HOST+'usage',data)
                .then(usage => {
                    if(usage.data.lines >= restrictions.data.total_lines ){
                        this.state.showModalRestriction = true;
                    }else if((value.name == 'Record Video + Cam' || value.name == 'Record video') && usage.data.record >= restrictions.data.screen_record ){
                        this.state.showModalRestriction = true;
                    }
                    if(!this.state.showModalRestriction){
                        this.props.selectItemChild(value,this.props.index);
                        this.props.hidenElement();
                    }else{
                        this.props.hidenElement();
                        this.props.showRestricModal(true);
                    }
                })
            }
        })
    }

    hideElement = (e) =>{
        e.stopPropagation();  
        this.props.hidenElement();
    }

    haddleChildren = (e) =>{
        e.stopPropagation();  
    }

    changeText = (e) =>{
        const value = e.target.value;

        this.setState({textFilter:value});
    }
    render(){
        return(
            <div class={this.props.className+ " list-show-container"} onClick={this.hideElement}>
                <div className="list-show-elements-plus">
                    <input onClick={this.haddleChildren} onChange={this.changeText} type="text"></input>
                    {this.state.elements.filter(value => value.name.toLowerCase().includes(this.state.textFilter.toLowerCase())).map((value,index) => 
                        
                        <div className="element" onClick={(e) => this.selectList(e,value)}>
                            <div className="element-img">
                                <img src={require('../Assets/'+value.icon)} alt="img" />
                            </div>
                            <div className="element-name">
                                <span>{value.name}</span>
                                {this.state.premium===false && value.name==="Record Video + Cam"?<img src={require('../Assets/premium.png')} alt="img" />:''}
                            </div>
                        </div>
                    )}
                </div> 
            </div>
        );
    }


}

export default ListElementWithText;