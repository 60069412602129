import React from 'react';
import axios from 'axios';

class ModalImage extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {

            elements : [

                {
                    img: 'bg1.jpg'
                },
                {
                    img: 'bg2.jpg'
                },
                {
                    img: 'bg3.jpg'
                },
                {
                    img: 'bg4.jpg'
                },
                {
                    img: 'bg5.jpg'
                },
                {
                    img: 'bg6.jpg'
                },
                {
                    img: 'bg7.jpg'
                },
                {
                    img: 'bg8.jpg'
                },
                {
                    img: 'bg9.jpg'
                },
                {
                    img: 'bg10.jpg'
                },
                {
                    img: 'bg11.jpg'
                },
                {
                    img: 'bg12.jpg'
                },
                {
                    img: 'bg13.jpg'
                },
                {
                    img: 'bg14.jpg'
                },
                {
                    img: 'bg15.jpg'
                },
                {
                    img: 'bg16.jpg'
                },
                {
                    img: 'bg17.jpg'
                },
                {
                    img: 'bg18.jpg'
                },
                {
                    img: 'bg19.jpg'
                },
                {
                    img: 'bg20.jpg'
                },
                {
                    img: 'bg21.jpg'
                },
                {
                    img: 'bg22.jpg'
                }

            ],

            gallery : true

        };

    }

    changeImage = (e,value) =>{
        this.props.changeEditor(e,value);
        this.props.changeVisible(e);
    }

    coverImage = () =>{

        const value = this.state.elements.map((value,index) => {

            return <div className="cover-preview" onClick={(e) => this.changeImage(e,value.img)}>
                
                <img src={require('../Assets/'+value.img)} alt="img" ></img>

            </div>

        });

        return (
            <div className="element-img-bg">
                {value}
            </div>

        );

    }

    hiddenModal = (e) =>{
        this.props.changeVisible(e);
    }

    onClickHandler = (event) => {
        event.stopPropagation();
        const data = new FormData() 
        console.log(event.target.files[0]);
        if(event.target.files[0]===undefined) return;
        let name = localStorage.getItem('uid')+"-"+event.target.files[0].name
        data.append('file', event.target.files[0],name)

        this.setState({preload:true});

        axios.post(process.env.REACT_APP_API_HOST+"upload", data, { // receive two parameter endpoint url ,form data 
      
        })
        .then(res => { // then print response status
        console.log(res)

            if(res.statusText==="OK"){

                const name = localStorage.getItem('uid')+"/"+res.data.originalname;
                this.props.changeEditor(event,name);
                this.props.changeVisible(event);
            }

        })
    }

    child = (e) =>{
        e.stopPropagation();
        this.setState({gallery:false});
    }

    childGallery = (e) =>{
        e.stopPropagation();
        this.setState({gallery:true});
    }

    

    render(){
        return(
            <div className="bg-modal-img" onClick={this.hiddenModal} style={{visibility: this.props.visible ? 'visible' : 'hidden' }}>

                <div className="moda-body">
                
                    <div className="select-cover">

                        <span>Select a cover for your page</span>

                    </div>


                    <div className="list-opc">
                        <div className={(this.state.gallery)?"gallery opc-active":"gallery"}  onClick={this.childGallery}> 
                            <span>Gallery</span>
                        </div>
                        <div className={(this.state.gallery)?"upload-link":"upload-link opc-active"}  onClick={this.child}>
                            <span>Upload</span>
                        </div>
                    </div>


                    <div className="modal-img" style={{display: this.state.gallery ? 'block' : 'none' }}>

                        {this.coverImage()}

                    </div>

                    <div className="modal-upload-img">
                        <div className="upload-file" style={{display: this.state.gallery ? 'none' : 'block' }}>
                            <button className="btn-file" >Choose an image</button>
                            <input type="file" name="file" id="file" className="btn-file-image" onClick={(e) => this.child(e)} onChange={this.onClickHandler}></input>
                        </div>
                    </div>

                </div>


            </div>
            
        );
    }

}




export default ModalImage;