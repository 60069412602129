import React from 'react';

class Cookies extends React.Component{
    
    
    render(){

        return (
            <div>
              <div className="navbar --fixed shadow">
                <div className="container">
                  <div className="col-3 md-5 sm-3 vertical--center">
                    <div className="logo"><a href="index.html"><img className="only-logo" src="img/logo_copack.png" alt="" style={{maxWidth: '210px'}} /><img className="only-icon" style={{width: '45px'}} src="img/favicon_bumper.png" alt="" /></a></div>
                  </div>
                  <div className="col-9 md-7 sm-9 menu-mobile v--center justify--right" id="main-menu">
                    <ul className="menu">
                      <li><a id="customLogin" href="/login"><span>Login</span></a></li>
                      <li><a id="customStart" className="btn --primary --sm" href="/signup"><span>Sign Up</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="section-header space-xl-top-inner">
                <div className="container" style={{display: "block", textAlign: "justify"}}>
                <p><strong>What are cookies?</strong></p>
                    <p><span style={{fontWeight: 400}}>The cookies used by this website do not contain any of your personal information, and we cannot use them to find out who you are.</span></p>
                    <p><span style={{fontWeight: 400}}>Cookies are small text files that websites save to your computer. They often include a randomly generated number which is stored on your device. Many cookies are automatically deleted after you finish using the website.</span></p>
                    <p><span style={{fontWeight: 400}}>Cookies are commonly used to improve browsing experience; measure website performance; support the delivery of services and support sharing information through social media, such as Twitter or Facebook.</span></p>
                    <p>&nbsp;</p>
                    <p><strong>Unless you have adjusted your browser settings to refuse cookies, our systems will issue cookies as soon you visit our website or access other services. If you have switched off cookies then some of the functionality of our services may not be available to you.</strong></p>
                    <p><span style={{fontWeight: 400}}>The cookies that we use on our websites only collect anonymous information they do not collect personal information.</span></p>
                    <p>&nbsp;</p>
                    <p><strong>How does our website use cookies?</strong></p>
                    <p><span style={{fontWeight: 400}}>Our website use cookies to make our website easier for you to use and improve your overall experience when accessing our online service. Using cookies to distinguish you from other users means that we can store your preferences, provide increased functionality and deliver better value for money.</span></p>
                    <p>&nbsp;</p>
                    <p><strong>Here are some examples of how we use cookies:</strong></p>
                    <ul>
                        <li style={{fontWeight: 400}}><strong>Improving your experience and supporting the delivery of our services:</strong><span style={{fontWeight: 400}}> To ensure that you don't have to repeat some actions unnecessarily and to make transactions as smooth as possible, we use cookies to remember that you are logged in; and to remember search terms and search results.</span></li>
                        <li style={{fontWeight: 400}}><strong>Measuring website performance:</strong><span style={{fontWeight: 400}}> our websites must demonstrate value for money in the delivery of information and services. Cookies support the monitoring by helping web teams to work out which pages are most useful to people, and to improve pages that people aren't finding useful. We also use cookies in order to monitor visitor numbers. No personally identifiable information such as your name or address is collected as part of this process.</span></li>
                    </ul>
                    <p><strong>What cookies are on our website?</strong></p>
                    <p><span style={{fontWeight: 400}}>We have audited our cookies and produced a list of the cookies we use which describes, categorises and explains which sites use them:</span></p>
                    <p><strong>_ga, _gid,: Installed by Google Analytics for two years</strong></p>
                    <p>&nbsp;</p>
                    <p><strong>Where can I find more about cookies and how to turn-off or delete them?</strong></p>
                    <p><span style={{fontWeight: 400}}>Find out more about cookies at </span><a href="http://www.allaboutcookies.org"><span style={{fontWeight: 400}}>www.allaboutcookies.org</span></a><span style={{fontWeight: 400}}> including information on how to control and delete them in all the major web browsers.</span></p>
                    <p><span style={{fontWeight: 400}}>You can block cookies by activating the setting on your browser that allows you to refuse all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access parts of our sites, or you may experience reduced functionality when accessing certain services. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies as soon you visit our website.</span></p>
                    <p><span style={{fontWeight: 400}}>For information about deleting cookies from the browser on your mobile phone, refer to your handset manual.</span></p>
                    <p>&nbsp;</p>
                    <p><strong>Contact us</strong></p>
                    <p><span style={{fontWeight: 400}}>If you have any questions about this policy or the web&rsquo;s use of cookies, please contact us by email </span><span style={{fontWeight: 400}}>support@Copack.com</span></p>
                </div>
              </div>
              <footer className="main-footer">
                <div className="container">
                  <div className="col-6 xs-12 text--center-xs"><img className="logo" src="img/logo_copack.png" alt="" style={{width: '210px'}} />
                    <p className="slogan">Superdocs to organise your mind</p>
                  </div>
                  <div className="col-6 xs-12 text--right text--center-xs">
                    <ul className="social-icons">
                      {/*<li><a className="icon-facebook" href="https://facebook.com/sitedoo" /></li>
                      <li><a className="icon-twitter" href="https://twitter.com/sitedoo_app" /></li>*/}
                      {/* <li><a class="icon-instagram" href=""></a></li>
                  <li><a class="icon-google-plus" href=""></a></li>
                  <li><a class="icon-linkedin" href=""></a></li> */}
                    </ul>
                  </div>
                </div>
              </footer>
              <div className="after-footer">
                <div className="container">
                  <div className="col-12 xs-12 text--center-xs"><a href="/privacy-policy">Privacy Policy</a></div>
                  <div className="col-12 xs-12 text--center-xs"><a href="/cookies">Cookie Policies</a></div>
                  <div className="col-6 xs-12 text--center-xs"><a className="contact-us" href>support@Copack.com</a></div>
                  <div className="col-6 xs-12 text--right text--center-xs">
                    <p className="copyright">Crafted lovingly with Copack</p>
                  </div>
                </div>
              </div>
            </div>
          );
    }

}




export default Cookies;