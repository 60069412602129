
import React from 'react';
import SideBar from './SideBar';
import Editor from './Editor';
import ModalRestrictions from './modalRestrictions'
import ModalAccountPremium from './ModalAccountPremium'
import ModalProfile from './ModalProfile'
import Footer from './Footer'
import socketIOClient from "socket.io-client";
import {
    withRouter
} from 'react-router-dom';
import axios from 'axios';
class Web extends React.Component{

    constructor(props){
        super(props);
        this.state = {

            pages : [],
            showRestrictionModal: false,
            showPremiumAccountModal: false,
            showModalProfile: false,
            profileOption: null,
            profileImage: '/img/Avatar.png',
            textRestrict: ''
        }

    

    }


    componentDidMount = () => {
        const value = localStorage.getItem('uid');
        if(!value){
            this.props.history.push('/home');
            
        }else{
            const socket = socketIOClient(process.env.REACT_APP_API_HOST);
            socket.emit("get-sidebar", value);
            socket.on("send-sidebar",data => this.setState(data,function update(){


                if(!this.props.match.params.page){
                    this.props.history.push('/'+this.state.pages[0].url);
                }
            }));

            socket.on("premium-account",data => {
                console.log('premium-account', data)
                if(data.email == localStorage.getItem('email'))
                    this.setState({showPremiumAccountModal: true})
            });
        }
        
        const data ={
            uid : localStorage.getItem('uid')
        }
        
        axios.post(process.env.REACT_APP_API_HOST+'getClient',data)
        .then(client => {
            if(client.data && client.data.profile_image){
                this.setProfileImage(client.data.profile_image)
            }
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    componentDidUpdate = () =>{
       
        let x = this.state;

        this.sleep(300).then(() => {

            if(x===this.state){

                if(this.state.pages.length===0){
                    console.log('hola mundo');
                    return;
                } 
                
                const params = {
                    'data': this.state,
                    'uid': localStorage.getItem('uid'),
                 };
          
                  axios.post(process.env.REACT_APP_API_HOST+"updatesidebar", params, { // receive two parameter endpoint url ,form data 
                    
                      headers: {
                          'content-type': 'application/json',
                      },
                
                  })
                  .then(res => { // then print response status
                    console.log(res)
          
                      if(res.statusText==="OK"){
                         console.log('update-sidebar');
                      }
          
                  })

            }
        
        })

    }

    searchItem = (props,id,value) =>{

        const sz = props.length;
        var stateCopy = Object.assign([], props);
        for(var i = 0; i< sz ; i++){
            if(stateCopy[i].id===id){
                if(value==="" && stateCopy[i].name==="Untitle") break;
                stateCopy[i].name = value;
               break;
            }else{
                if(stateCopy[i].subpage.length>0){
                    stateCopy[i].subpage = this.searchItem(stateCopy[i].subpage,id,value);
                }
            }
        }

        return stateCopy;

    }

    AddEmoji = (props,id,value) =>{

        const sz = props.length;
        var stateCopy = Object.assign([], props);
        for(var i = 0; i< sz ; i++){
            if(stateCopy[i].id===id){
                stateCopy[i].icon = value;
               break;
            }else{
                if(stateCopy[i].subpage.length>0){
                    stateCopy[i].subpage = this.AddEmoji(stateCopy[i].subpage,id,value);
                }
            }
        }

        return stateCopy;

    }

    changeTitle = (value,id) =>{


        var stateCopy = this.searchItem(this.state.pages,id,value);

        var stateCopy2 = Object.assign({}, this.state);
        stateCopy2.pages = Object.assign([],stateCopy);
        this.setState(stateCopy2);


    }

    changeEmoji = (value,id) =>{


        console.log(value);


        var stateCopy = this.AddEmoji(this.state.pages,id,value);

        var stateCopy2 = Object.assign({}, this.state);
        stateCopy2.pages = Object.assign([],stateCopy);
        this.setState(stateCopy2);
    }


    updateStateList = (value) =>{
        this.setState(value);
    }

    showRestricModal = (value, text) => {
        let textRestrict = 'Your free account has exceeded the number of allowed elements, to continue enjoying the service purchase a premium plan';
        if(text)
            textRestrict = text
        
        this.setState({showRestrictionModal: value, textRestrict: textRestrict});

    }

    showPremiumModal = (value) => {
        this.setState({showPremiumAccountModal: value});
    }


    showProfileModal = (value, profileOption) => {
        this.setState({showModalProfile: value, profileOption: profileOption});
    }

    setProfileImage = (image)  => {
        this.setState({profileImage: process.env.REACT_APP_API_HOST + image})
    }
    render(){

      

        return(

             <div className={(this.state.hidesidebar)===true?"container-dsm hide-bar":"container-dsm"}>
               {this.state.showRestrictionModal===true?<ModalRestrictions message={this.state.textRestrict} showRestricModal={this.showRestricModal}></ModalRestrictions>:""}
               {this.state.showPremiumAccountModal===true?<ModalAccountPremium showPremiumModal={this.showPremiumModal}></ModalAccountPremium>:""}
               {this.state.showModalProfile===true?<ModalProfile setProfileImage={this.setProfileImage} showProfileModal={this.showProfileModal} profileOption={this.state.profileOption}></ModalProfile>:""}
               {(this.state.pages.length > 0) && <SideBar updateStateList={this.updateStateList} list={this.state} page={this.props.match.params.page}></SideBar>}
               {(this.state.pages.length > 0) && <Editor profileImage={this.state.profileImage} showProfileModal={this.showProfileModal} showRestricModal={this.showRestricModal} updateEmoji={this.changeEmoji} updateTitle={this.changeTitle} page={this.props.match.params.page}></Editor>}
            </div>

        );
    }


}

export default withRouter(Web);