import React, { Component } from 'react';
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js';

//const firebaseApp = firebase.initializeApp(firebaseConfig);

class ModalAccountPremium extends Component {

  constructor(props){
    super(props); 

    this.state = [

    ];
  }
  closeModal= ()  => {
    this.props.showPremiumModal(false)
  }

    render() {
      return (
        <div className="modal-container-premium">

        <div className="premium-modal">
            <div className="modal-close">
              <Icon color="#342e37" onClick={this.closeModal} path={mdiClose}/>
            </div>
            {
              <h3>ACCOUNT UPGRADE</h3>
            }
            <div className="premium-modal-body">
                <span>Your account was upgraded to premium, now you can enjoy all the benefits of the platform without any restrictions.</span>
            </div>
            <button onClick={this.closeModal} > OK</button>
        </div>
        
        
        </div>
      );
    }
  } 

export default ModalAccountPremium;