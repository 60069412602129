import React from 'react';
import ModalImage from './ModalImage';
class Cover extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {

            show : false,
            showModal : false,
            reposicion: false,
            pos:0,
            y:0,

        };


    }


    componentDidMount = () =>{
        if(this.props.posInicial!==undefined){
            this.setState({
                pos:this.props.posInicial
            })
        }

     
    }

    componentDidUpdate = () =>{
        /*if(this.state.reposicion!==this.props.reposicion){
            this.setState({reposicion:this.props.reposicion});
        }*/
    }

    componentWillReceiveProps = () =>{

        if(this.state.pos!==0){
            this.setState({pos:0});
        }
    }


  

    showModalImag = () =>{

        this.setState({showModal:true});

    }


    changeVisibleModal = (e) =>{
        e.stopPropagation();
        this.setState({showModal:false});

    }


    showIcon() {
        this.setState({show:true},function(){

            console.log(this.state.show);
        });
    };
    
    hideIcon() {
        this.setState({show:false});
    };


    changeImage = (e,value) =>{
        e.stopPropagation();
        this.props.changeBG(value);
    }

    reposicionImag = (e) =>{
        this.setState({reposicion:true});
        this.setState({pos:this.props.posInicial});
        this.props.showCover(true);
    }

    savereposicionImag = (e) =>{
        e.stopPropagation();
        this.props.posBG(this.state.pos);
        this.setState({reposicion:false});
        this.props.showCover(false);
    }

    cancelreposicionImag = (e) =>{
        e.stopPropagation();
        this.setState({reposicion:false});
        this.setState({pos:this.props.posInicial});
        this.props.showCover(false);
    }

    handleEvent = (e) =>{
        console.log(this.state.pos);
        var y = e.screenY;
        if(this.state.pos<=40 && this.state.pos>=-40){   

            if(y-this.state.y>0){
                if(this.state.pos+1===41) return;
                this.setState({pos:this.state.pos+1});
            }else{
                if(this.state.pos-1===-41) return;
                this.setState({pos:this.state.pos-1});
            }
            this.setState({y:y});
        
        }

        
    }


    handleEventDown = (e) =>{
        this.setState({mousedown:true});
        this.setState({y:e.screenY});
    }

    handleEventUp = () =>{
        this.setState({mousedown:false});
    }

    empty = () =>{

    }
    
    

    render(){
  
        return(
            <div  className={this.state.reposicion?"bg-hero cross":"bg-hero"} onMouseUp={ this.handleEventUp} onMouseDown={this.state.reposicion?this.handleEventDown:this.empty} onMouseMove={(this.state.reposicion && this.state.mousedown)?this.handleEvent:this.empty} onMouseEnter={ this.showIcon.bind(this) }
            onMouseLeave={ this.hideIcon.bind(this) }>
                <ModalImage visible={this.state.showModal} changeVisible={this.changeVisibleModal} changeEditor={this.changeImage}></ModalImage>
                <img draggable="false" style={{marginTop:this.state.pos===0?this.props.posInicial:this.state.pos}} src={this.props.content.split("/").length===1?require('../Assets/'+this.props.content):process.env.REACT_APP_RESORUCE_URL+this.props.content} alt="img" />
                <div className="change-cover"  onClick={this.showModalImag}  style={{visibility: (this.state.show==true && this.state.reposicion==false) ? 'visible' : 'hidden' }}>
                    <span>Change Cover</span>
                </div>
                <div className="reposicion-cover"  onClick={this.reposicionImag}  style={{visibility: (this.state.show===true && this.state.reposicion===false) ? 'visible' : 'hidden' }}>
                    <span>Reposición Cover</span>
                </div>
                <div className="save-reposicion-cover"  onClick={this.savereposicionImag}  style={{visibility: this.state.reposicion ? 'visible' : 'hidden' }}>
                    <span>Save Reposición</span>
                </div>
                <div className="cancel-reposicion-cover"  onClick={this.cancelreposicionImag}  style={{visibility: this.state.reposicion ? 'visible' : 'hidden' }}>
                    <span>Cancel</span>
                </div>
            </div>
            
        );
    }

}




export default Cover;