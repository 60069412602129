
import React from 'react';
import Editor from './Editor';
import axios from 'axios';
import socketIOClient from "socket.io-client";
import SideBar from './SideBar';
import { mdiConsoleNetworkOutline } from '@mdi/js';

class Web extends React.Component{

    constructor(props){
        super(props);
        this.state = {

            pages : [],
            private:false,
            id_page:null,
            hidesidebar:false

        }

    

    }

    componentDidMount = () =>{

        var jwt = require('jwt-simple');

        var decoded = jwt.decode(this.props.match.params.page, 'copack');

        const dir = decoded.dir.split("/");        
        const data = {
            uid : dir[0],
            page: dir[1]
        }

        console.log(data);

        let url = data.page;

        const socket = socketIOClient(process.env.REACT_APP_API_HOST);
        socket.emit("get-sidebar", data.uid);
        socket.on("send-sidebar",data => {
           let pages = this.getListPages(data.pages,url);
           if(pages!==null){
                let pags = [];
                pags.push(pages);
                this.setState({
                    pages: pags
                })
            }
        });


        if(decoded.status===true){
            this.setState({
                private:true,
                uid:dir[0],
                id_page:dir[1]
            })
        }else{
            this.setState({
                private:false,
                uid:dir[0],
                id_page:dir[1]
            })
        }
    }

    getListPages = (pages,url_page) =>{

        let data = null;

        console.log(pages);
        console.log(url_page);

        for(var i=0;i<pages.length;i++){
            if(pages[i].id==url_page){
                if(pages[i].subpage.length>0){
                    return pages[i];
                }
            }else{
                if(pages[i].subpage.length>0){
                    if(data!==null){
                        return;
                    }
                    data = this.getListPages(pages[i].subpage,url_page); 
                }
            }
        }

        return data;
    }

    changePage = (id) =>{
        this.setState({
            id_page:id
        })
    }

    hideSidebar = () =>{
        this.setState({
            hidesidebar:!this.state.hidesidebar
        })
    }


    render(){

        return(
             <div className={this.state.pages.length>0?(this.state.hidesidebar!==false?"container-dsm container-multiply hide-bar":"container-dsm container-multiply"):"container-dsm-shared"}>
                  {this.state.pages.length>0?<SideBar hideSidebar={this.hideSidebar} list={this.state.pages} hidesidebar={this.state.hidesidebar} changePage={this.changePage}></SideBar>:""}
                 {this.state.uid!==null && this.state.id_page!==null?<Editor id_page={this.state.id_page} uid={this.state.uid} private={this.state.private} ></Editor>:""} 
            </div>
        );

    }


}

export default Web;