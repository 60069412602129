import React from 'react';
import axios from 'axios';
//import '../css/normalize.css';
import '../css/stripe.css';
import { loadStripe } from "@stripe/stripe-js";

class Pricing extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            basicPriceId: '',
            proPriceId: '',
            error: ''
        };
    }

    handleFetchResult = function(result) {
        if (!result.ok) {
            return result.json().then(function(json) {
                if (json.error && json.error.message) {
                    throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
                }
            }).catch(function(err) {
                this.setState({error: err.error.message});
                throw err;
            });
        }
        return result.json();
    };

    setCheckoutSession = (priceId) => {
        const data = {
            priceId: priceId,
            email: localStorage.getItem('email')
        }
        axios.post(process.env.REACT_APP_API_HOST+'stripe/create-checkout-session', data)
        .then(async result => {
            console.log(result)
            if (result.error) {
                this.setState({error: result.error.message});
            }else{
                const stripePromise = loadStripe(
                this.state.publishableKey
                );
                const stripe = await stripePromise;

                stripe.redirectToCheckout({
                    sessionId: result.data.sessionId
                })
            }
        })
        .catch(function(err){
            console.log(err)
        })
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_HOST+'stripe/setup')
        .then(result => {
            if (!result.ok) {
                try {
                    const json = result.data 
                    if (json.error && json.error.message) {
                        throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
                    }else{
                        this.setState({basicPriceId: json.basicPrice});
                        this.setState({proPriceId: json.proPriceId});
                        this.setState({publishableKey: json.publishableKey});
                    }

                    
                } catch(err) {
                    this.setState({error: err.error.message});
                    throw err;
                };
            }
        })
    }
    render(){
        
        return (
            <div className="html">
                <div className="body">
                    <div className="togethere-background"></div>
                    <div className="sr-root">
                    <div className="sr-main">
                        <header className="sr-header">
                        <img
                            src="/img/logo_copack.png"
                            width="200"
                        />
                        </header>
                        <h1>Suscripción Copack Pro</h1>
                        <ul>
                        <li>Licencia 1 usuario </li>
                        <li>Grabación de pantalla y webcam ilimitado hasta 1GB por usuario</li>
                        <li>Grabación de vídeos hasta 30 minutos</li>
                        <li>Creación ilimitada de párrafos y añadir módulos</li>
                        <li>Lead generation (private pages)</li>
                        <li>Soporte 24/7</li>
                        <li>Añadir pasarela de pago para tus usuarios (coming soon)</li>
                        </ul>
                        <div className="price-table-container">
                        <section>
                            <img
                            src="/img/pricing/starter.png"
                            width="120"
                            height="120"
                            />
                            <div className="name">Mensual</div>
                            <div className="price">$49</div>
                            <button id="basic-plan-btn" onClick={() => this.setCheckoutSession(this.state.basicPriceId)}>Seleccionar</button>
                        </section>
                        <section>
                            <img
                            src="/img/pricing/professional.png"
                            width="120"
                            height="120"
                            />
                            <div className="name">Anual</div>
                            <div className="price">$190</div>
                            <button id="pro-plan-btn" onClick={() => this.setCheckoutSession(this.state.proPriceId)}>Seleccionar</button>
                        </section>
                        </div>
                    </div>
                    </div>
                    <div id="error-message" className="error-message">{this.state.error}</div>
                </div>
                </div>
        ) 
    }

}
export default Pricing;
