import React from 'react';
class Cover extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {

            show : false,

            showModal : false

        };


    }
    

    render(){
  
        return(
            <div className="bg-hero">
               <img src={this.props.content.split("/").length===1?require('../Assets/'+this.props.content):process.env.REACT_APP_RESORUCE_URL+this.props.content} alt="img" />
            </div>
            
        );
    }

}




export default Cover;