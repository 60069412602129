import React, { useState }   from 'react';
import { mdiChevronDown } from '@mdi/js';
import { mdiChevronRight } from '@mdi/js';
import { mdiDotsHorizontal } from '@mdi/js';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react'
import {
  Link
} from "react-router-dom";

import 'emoji-mart/css/emoji-mart.css'
import { Emoji } from 'emoji-mart';

let id_move;


const ListOptions = ({options,ChangeOption,addPage,showMenuOption,removeElement,moveElement,duplicateItem,showIcons,hideIcons,active}) =>{
    
    const showIcon = (index) => {
      showIcons(index);
    };

    const hideIcon = (index) => {
      hideIcons(index)
    };

    const showElement = (index) =>{
        ChangeOption(index);
    }

    const createPage = (index) =>{
      addPage(index);
    }

    const showMenu = (index) =>{
      showMenuOption(index);
    }

    const removeItem = (event,index) =>{
      event.stopPropagation();
      removeElement(index);
    }

    const duplicarItem = (event,index) =>{
      event.stopPropagation(); 
      duplicateItem(index);
    }

    const dragStartHandler = (event) =>{
      console.log(event.target.id);
      id_move = event.target.id;
    }

    const dragStartDrop = (event) =>{
      event.preventDefault();
    }

    const onDragOver = (event) =>{
      event.preventDefault();
    }

    const onFileDrop = (id,event) =>{
      event.preventDefault();
      event.stopPropagation();
      
      if(id.toString()!==id_move){
        moveElement(id_move,id);
      }


    }
    
    
    return (



        <div className="sidebar-list">
          {options.map((option,index) => (
            <ul id={option.id} draggable="true" onDragStart={dragStartHandler.bind(this)} onDragEnter={dragStartDrop.bind(this)}    onDragOver={onDragOver.bind(this)} onDrop={ onFileDrop.bind(this,option.id)}>
                 
                <div className="element-name-opc"  onMouseEnter={ showIcon.bind(this,option.id) }
            onMouseLeave={ hideIcon.bind(this,option.id) }>
                    {/*<div className="sliderbar-hover">
                    </div>*/}
                    {(option.subpage.length > 0 ) && 
                    <div className="icon-more">
                        <Icon onClick={() => showElement(option.id)} color="#5A687C" path={option.show?mdiChevronDown:mdiChevronRight}/>
                    </div>}
                    <div className="emoji-icon-list">
                      <Emoji  emoji={{ id: option.icon, skin: 3 }} size={24} />
                    </div>
                    <div className="elment-name">
                    <Link to={"/"+option.url}  id={option.id}><span  className={parseInt(active, 10)===option.id?'bold-title':''}>{option.name.replace(/&nbsp;/g,'')}</span></Link>
                    </div>
                    {(option.showicon) && <div className="icon-opc">
                        <Icon color="#5A687C" onClick={() => showMenu(option.id)}   path={mdiDotsHorizontal} />
                        {(option.showoption === true ) && <div className="list-option-sidebar">
                          <ul>
                              <li onClick={(e) => removeItem(e,option.id)} >  <img src={require('../Assets/trash.png')} alt="img"></img><span>Delete </span></li>
                              <li onClick={(e) => duplicarItem(e,option.id)}><img src={require('../Assets/copy.png')} alt="img"></img> <span>Duplicate</span></li>
                          </ul>
                        </div>}
                        <Icon color="#5A687C" onClick={() => createPage(option.id)} path={mdiPlus} />
                    </div>}
                    
                </div>  
           
               
              {(option.subpage.length > 0 && option.show===true) &&
                <ListOptions
                  options={option.subpage}
                  ChangeOption = {ChangeOption}
                  addPage={addPage}
                  showMenuOption = {showMenuOption}
                  removeElement = {removeElement}
                  duplicateItem = {duplicateItem}
                  showIcons = {showIcons}
                  hideIcons = {hideIcons}
                  active = {active}
                  moveElement = {moveElement}
                 />
              }
            </ul>
          ))}
        </div>
      )

}

export default ListOptions;

