import React, { useState }   from 'react';
import {
  Link
} from "react-router-dom";

import 'emoji-mart/css/emoji-mart.css'
import { Emoji } from 'emoji-mart';
import Icon from '@mdi/react'
import { mdiChevronDown } from '@mdi/js';
import { mdiChevronRight } from '@mdi/js';


const ListOptions = ({options,active,ChangeOption, ChangePage}) =>{
    
  console.log(active);


  const showElement = (index) =>{
    ChangeOption(index);
  }

  const changePage = (index) =>{
    ChangePage(index);
  }
    
    return (
        <div className="sidebar-list">
          {options.map((option,index) => (
            <ul id={option.id}>
                 
                <div className="element-name-opc">
                    {/*<div className="sliderbar-hover">
                    </div>*/}
                    {(option.subpage.length > 0 ) && 
                    <div className="icon-more">
                        <Icon onClick={() => showElement(option.id)} color="#5A687C" path={option.show?mdiChevronDown:mdiChevronRight}/>
                    </div>}
                    <div className="emoji-icon-list">
                      <Emoji  emoji={{ id: option.icon, skin: 3 }} size={24} />
                    </div>
                    <div className="elment-name">
                    <div onClick={() => changePage(option.id)}  id={option.id}><span className={parseInt(active, 10)===option.id?'bold-title':''}>{option.name.replace(/&nbsp;/g,'')}</span></div>
                    </div>        
                </div>  
           
               
              {(option.subpage.length > 0 && option.show===true) &&
                <ListOptions
                  options={option.subpage}
                  active = {active}
                  ChangeOption = {ChangeOption}
                  ChangePage = {ChangePage}
                 />
              }
            </ul>
          ))}
        </div>
      )

}

export default ListOptions;

