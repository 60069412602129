import React from 'react';

class Video extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            show:false
        };
    }



    render(){
        return(
            <div className="element-editor">
                <div className="remove" style={{visibility: this.state.show ? 'visible' : 'hidden' }}>
                </div>
                <div className="element-file">
                        <video height="400" controls>
                            <source src={process.env.REACT_APP_RESORUCE_URL+this.props.content} type="video/mp4"></source>
                        </video>
                
                </div>
            </div>
        );
    }

}




export default Video;