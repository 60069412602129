import React, { Component } from 'react';
import axios from 'axios';
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js';
import BodyLeads from './BodyLeads'; 
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import Subscription from './Subscription';
import Payment from './Payment';


class ModalProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
          file: '',
          imagePreviewUrl: '',
          optionSelected: '',
          showLeads: false,
          userData: {}
        };
        this._handleImageChange = this._handleImageChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
      }
    
      _handleSubmit(e) {
        e.preventDefault();
        // TODO: do something with -> this.state.file
      }
    
      _handleImageChange(e) {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result
          });
        }
    
        reader.readAsDataURL(file)
      }


    closeModal= ()  => {
        this.props.showProfileModal(false)
    }

    componentDidMount() {
        this.setState({optionSelected: this.props.profileOption})

        const data ={
            uid : localStorage.getItem('uid')
        }

        axios.post(process.env.REACT_APP_API_HOST+'getClient',data)
        .then(client => {
            if(client.data && client.data.type_client!= 'basic'){
                this.setState({showLeads: true})
            }
        })
    }

    changeOption(option) {
        this.setState({optionSelected: option})
    }

    cancelSubscription= ()  => {
      this.closeModal()
    }

    goToPricePage= ()  => {
      window.open("https://app.copack.io/pricing", "_blank")
    }

    render() {
      return (
        <div className="modal-container-profile">

        <div className="profile-modal">
            <div className="modal-close">
              <Icon color="#342e37" onClick={this.closeModal} path={mdiClose}/>
            </div>
            <div className="profile-content">
                <div className="sidebar-profile">
                    
                    <div className={(this.state.optionSelected === 'leads')? 'profile-option selected-option' : 'profile-option'} onClick={()  => this.changeOption('leads')}>
                        Leads
                    </div>
                    <div className={(this.state.optionSelected === 'profile')? 'profile-option selected-option' : 'profile-option'}  onClick={ ()  => this.changeOption('profile')}>
                        Information
                    </div>
                    <div className={(this.state.optionSelected === 'password')? 'profile-option selected-option' : 'profile-option'} onClick={ ()  => this.changeOption('password')}>
                        Change password
                    </div>
                    {this.state.showLeads?
                    <div className={(this.state.optionSelected === 'subscription')? 'profile-option selected-option' : 'profile-option'} onClick={ ()  => this.changeOption('subscription')}>
                        Subscription
                    </div>: ''}
                    {/*this.state.showLeads?
                    <div className={(this.state.optionSelected === 'subscription')? 'profile-option selected-option' : 'profile-option'} onClick={ ()  => this.changeOption('payment')}>
                        Payment
                    </div>: ''*/}
                </div>
                {this.state.optionSelected === 'leads' && this.state.showLeads?<BodyLeads></BodyLeads>:""}
                {this.state.optionSelected === 'leads' && !this.state.showLeads?
                   <div className="profile-details">
                      {
                        <h2>Premium feature</h2>
                      }
                      <div className="restrict-modal-body">
                          <h4>This  feature is available only in the premium version. Buy a subscription to get this feature and many more. </h4>

                          <ul>
                            <li>1 user license</li>
                            <li>Unlimited webcam and screen recording up to 1GB per user</li>
                            <li>Video recording up to 30 minutes</li>
                            <li>Unlimited paragraph creation and adding modules</li>
                            <li>Lead generation (private pages)</li>
                            <li>24/7 support</li>
                            <li>Add payment gateway for your users (coming soon)</li>
                          </ul>

                      </div>
                      <button className="btn-premium-account" onClick={this.goToPricePage} > buy subscription</button>
                  </div>: ''
                }
                {this.state.optionSelected === 'profile'?<Profile setProfileImage={this.props.setProfileImage}></Profile>:""}
                {this.state.optionSelected === 'password'?<ChangePassword></ChangePassword>:""}
                {this.state.optionSelected === 'subscription' && this.state.showLeads?<Subscription></Subscription>: ''}
                {this.state.optionSelected === 'payment' && this.state.showLeads?<Payment></Payment>:""}
            </div>
        </div>
        
        
        </div>
      );
    }
  } 

export default ModalProfile;