import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';




class VerifyUser extends Component {

  constructor(props){
    super(props); 

    this.state = {
      error:false
    };
  }




    render() {

    
      return (
        <div>
          <div className='verify-user'>
              <img src={require("../Assets/check.png")}></img>
              <span>The payment was made correctly <a href="/login">Click here to enter</a></span>
          </div>
        </div>
      );
    }
  } 

export default VerifyUser;