import React from 'react';

class Footer extends React.Component{
    
    
    render(){

        return (
            <div>
              <div className="after-footer footer-app">
                <div className="container">
                <div className="col-8 xs-12 text--right text--center-xs footer-left">
                    <div className="col-8 xs-12 text--center-xs"><img className="logo" src="/img/logo_copack.png" alt="" style={{width: '100'}} />
                        <p className="slogan">Superdocs to organise your mind</p>
                    </div>
                </div>
                <div className="col-2 xs-12 text--right text--center-xs footer-right">
                    <div className="col-2 xs-12 text--center-xs" style={{marginBottom: '7px'}}><a href="/privacy-policy">Privacy Policy</a></div>
                    <div className="col-2 xs-12 text--center-xs" style={{marginBottom: '7px'}}><a href="/cookies">Cookie Policies</a></div>
                    <div className="col-2 xs-12 text--center-xs" style={{marginBottom: '7px'}}><a className="contact-us" href>support@Copack.com</a></div>
                </div>
                  
                </div>
              </div>
            </div>
          );
    }

}




export default Footer;