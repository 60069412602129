import React, { Component } from 'react';
import moment from 'moment'
import axios from 'axios';

//const firebaseApp = firebase.initializeApp(firebaseConfig);

class Payment extends Component {

    constructor(props){
      super(props); 

      this.state = {
        access_token:null,
        url:null
      }

    }


    componentDidMount = () =>{

      (async () => {
        try {
          const { data: { access_token } } = await axios({
            url: 'https://api.sandbox.paypal.com/v1/oauth2/token',
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Accept-Language': 'en_US',
              'content-type': 'application/x-www-form-urlencoded',
            },
            auth: {
              username: 'ASUeGYYmT4xr9QdyvOlgjXAbaz7-pBz85gPDrSULzA8-rv-rVixkChuQJHbE5H4B_7zD2ebwFxl_kjSk',
              password: 'EPBpfyklAjFvdV3tDC3qucztXGHREUCexLAwWxd8kvlnRYGkwAhhtfUt-ck7PIp5eZ2O4rqJsfIoOFW4',
            },
            params: {
              grant_type: 'client_credentials',
            },
          });

          this.setState({
            access_token:access_token
          });


          var data = JSON.stringify({"operations":[{"operation":"API_INTEGRATION","api_integration_preference":{"rest_api_integration":{"integration_method":"PAYPAL","integration_type":"THIRD_PARTY","third_party_details":{"features":["PAYMENT","REFUND"]}}}}],"products":["EXPRESS_CHECKOUT"],"legal_consents":[{"type":"SHARE_DATA_CONSENT","granted":true}]});
          var config = {
            method: 'post',
            url: 'https://api-m.sandbox.paypal.com/v2/customer/partner-referrals',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer '+access_token
            },
            data : data
          };

          let ev = this;

          axios(config)
          .then(function (response) {
            console.log(response.data);
            let url = response.data.links[1]["href"];
            ev.setState({
              url:url
            })    
          })
      
          console.log('access_token: ', access_token);
        } catch (e) {
          console.error(e);
        }
      })();
    }


    addurl = () =>{


      // MjJjNDhlNmYtMDhmNC00MzMxLWI5Y2MtMjcyODQ5NDNkNDFlTmJQbXNpc1BGN1RYWkJjZHlxRTBEZWhKRWJ6WmovOFFNQWx2MElHRit1bz12Mg==

      (async () => {
        try {
          
          var data = JSON.stringify({
            "individual_owners": [
              {
                "names": [
                  {
                    "prefix": "Mr.",
                    "given_name": "John",
                    "surname": "Doe",
                    "middle_name": "Middle",
                    "suffix": "Jr.",
                    "full_name": "John Middle Doe Jr.",
                    "type": "LEGAL"
                  }
                ],
                "citizenship": "US",
                "addresses": [
                  {
                    "address_line_1": "One Washington Square",
                    "address_line_2": "Apt 123",
                    "admin_area_2": "San Jose",
                    "admin_area_1": "CA",
                    "postal_code": "95112",
                    "country_code": "US",
                    "type": "HOME"
                  }
                ],
                "phones": [
                  {
                    "country_code": "1",
                    "national_number": "6692468839",
                    "extension_number": "1234",
                    "type": "MOBILE"
                  }
                ],
                "birth_details": {
                  "date_of_birth": "1955-12-29"
                },
                "type": "PRIMARY"
              }
            ],
            "business_entity": {
              "business_type": {
                "type": "INDIVIDUAL",
                "subtype": "ASSO_TYPE_INCORPORATED"
              },
              "business_industry": {
                "category": "1004",
                "mcc_code": "2025",
                "subcategory": "8931"
              },
              "business_incorporation": {
                "incorporation_country_code": "US",
                "incorporation_date": "1986-12-29"
              },
              "names": [
                {
                  "business_name": "Test Enterprise",
                  "type": "LEGAL_NAME"
                }
              ],
              "emails": [
                {
                  "type": "CUSTOMER_SERVICE",
                  "email": "customerservice@example.com"
                }
              ],
              "website": "https://mystore.testenterprises.com",
              "addresses": [
                {
                  "address_line_1": "One Washington Square",
                  "address_line_2": "Apt 123",
                  "admin_area_2": "San Jose",
                  "admin_area_1": "CA",
                  "postal_code": "95112",
                  "country_code": "US",
                  "type": "WORK"
                }
              ],
              "phones": [
                {
                  "country_code": "1",
                  "national_number": "6692478833",
                  "extension_number": "1234",
                  "type": "CUSTOMER_SERVICE"
                }
              ],
              "beneficial_owners": {
                "individual_beneficial_owners": [
                  {
                    "names": [
                      {
                        "prefix": "Mr.",
                        "given_name": "John",
                        "surname": "Doe",
                        "middle_name": "Middle",
                        "suffix": "Jr.",
                        "full_name": "John Middle Doe Jr.",
                        "type": "LEGAL"
                      }
                    ],
                    "citizenship": "US",
                    "addresses": [
                      {
                        "address_line_1": "One Washington Square",
                        "address_line_2": "Apt 123",
                        "admin_area_2": "San Jose",
                        "admin_area_1": "CA",
                        "postal_code": "95112",
                        "country_code": "US",
                        "type": "HOME"
                      }
                    ],
                    "phones": [
                      {
                        "country_code": "1",
                        "national_number": "6692468839",
                        "extension_number": "1234",
                        "type": "MOBILE"
                      }
                    ],
                    "birth_details": {
                      "date_of_birth": "1955-12-29"
                    },
                    "percentage_of_ownership": "50"
                  }
                ],
                "business_beneficial_owners": [
                  {
                    "business_type": {
                      "type": "INDIVIDUAL",
                      "subtype": "ASSO_TYPE_INCORPORATED"
                    },
                    "business_industry": {
                      "category": "1004",
                      "mcc_code": "2025",
                      "subcategory": "8931"
                    },
                    "business_incorporation": {
                      "incorporation_country_code": "US",
                      "incorporation_date": "1986-12-29"
                    },
                    "names": [
                      {
                        "business_name": "Test Enterprise",
                        "type": "LEGAL_NAME"
                      }
                    ],
                    "emails": [
                      {
                        "type": "CUSTOMER_SERVICE",
                        "email": "customerservice@example.com"
                      }
                    ],
                    "website": "https://mystore.testenterprises.com",
                    "addresses": [
                      {
                        "address_line_1": "One Washington Square",
                        "address_line_2": "Apt 123",
                        "admin_area_2": "San Jose",
                        "admin_area_1": "CA",
                        "postal_code": "95112",
                        "country_code": "US",
                        "type": "WORK"
                      }
                    ],
                    "phones": [
                      {
                        "country_code": "1",
                        "national_number": "6692478833",
                        "extension_number": "1234",
                        "type": "CUSTOMER_SERVICE"
                      }
                    ],
                    "percentage_of_ownership": "50"
                  }
                ]
              },
              "office_bearers": [
                {
                  "names": [
                    {
                      "prefix": "Mr.",
                      "given_name": "John",
                      "surname": "Doe",
                      "middle_name": "Middle",
                      "suffix": "Jr.",
                      "full_name": "John Middle Doe Jr.",
                      "type": "LEGAL"
                    }
                  ],
                  "citizenship": "US",
                  "addresses": [
                    {
                      "address_line_1": "One Washington Square",
                      "address_line_2": "Apt 123",
                      "admin_area_2": "San Jose",
                      "admin_area_1": "CA",
                      "postal_code": "95112",
                      "country_code": "US",
                      "type": "HOME"
                    }
                  ],
                  "phones": [
                    {
                      "country_code": "1",
                      "national_number": "6692468839",
                      "extension_number": "1234",
                      "type": "MOBILE"
                    }
                  ],
                  "birth_details": {
                    "date_of_birth": "1955-12-29"
                  },
                  "role": "DIRECTOR"
                }
              ],
              "annual_sales_volume_range": {
                "minimum_amount": {
                  "currency_code": "USD",
                  "value": "10000"
                },
                "maximum_amount": {
                  "currency_code": "USD",
                  "value": "50000"
                }
              },
              "average_monthly_volume_range": {
                "minimum_amount": {
                  "currency_code": "USD",
                  "value": "1000"
                },
                "maximum_amount": {
                  "currency_code": "USD",
                  "value": "50000"
                }
              },
              "purpose_code": "P0104"
            },
            "email": "accountemail@example.com",
            "preferred_language_code": "en-US",
            "tracking_id": "testenterprices123122",
            "partner_config_override": {
              "partner_logo_url": "https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_111x69.jpg",
              "return_url": "https://testenterprises.com/merchantonboarded",
              "return_url_description": "the url to return the merchant after the paypal onboarding process.",
              "action_renewal_url": "https://testenterprises.com/renew-exprired-url",
              "show_add_credit_card": true
            },
            "operations": [
              {
                "operation": "BANK_ADDITION"
              }
            ],
            "financial_instruments": {
              "banks": [
                {
                  "nick_name": "Bank of America",
                  "account_number": "123405668293",
                  "account_type": "CHECKING",
                  "currency_code": "USD",
                  "identifiers": [
                    {
                      "type": "ROUTING_NUMBER_1",
                      "value": "123456789"
                    }
                  ]
                }
              ]
            },
            "legal_consents": [
              {
                "type": "SHARE_DATA_CONSENT",
                "granted": true
              }
            ],
            "products": [
              "EXPRESS_CHECKOUT"
            ]
          });
       
          var config = {
            method: 'post',
            url: 'https://api-m.sandbox.paypal.com/v2/customer/partner-referrals',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer '+this.state.access_token
            },
            data : data
          };

          axios(config)
          .then(function (response) {
            console.log(response.data);
          
          })
          .catch(function (error) {
            console.log(error);
          });

        } catch (e) {
          console.error(e);
        }
      })();

    }


    addurl2 = () =>{


      // MjJjNDhlNmYtMDhmNC00MzMxLWI5Y2MtMjcyODQ5NDNkNDFlTmJQbXNpc1BGN1RYWkJjZHlxRTBEZWhKRWJ6WmovOFFNQWx2MElHRit1bz12Mg==

      (async () => {
        try {
          
  
       
          var config = {
            method: 'get',
            url: 'https://api-m.sandbox.paypal.com/v2/customer/partner-referrals/ZDM4ZjM2ZmUtOGI2NC00YzMwLWJhNDUtZTM3MDE2NmVjMzE5Z1BpeWhEY2VYMGdGbDBtNDBuOU5kWFBsSUsrVk8vejdHbFNkQUVXT2R2WT12Mg==',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer '+this.state.access_token
            }
          };

          axios(config)
          .then(function (response) {
            console.log(response.data);
          
          })
          .catch(function (error) {
            console.log(error);
          });

        } catch (e) {
          console.error(e);
        }
      })();

    }

    onboardedCallback = (authCode, sharedId) =>{

      console.log(authCode,sharedId);

    }

    render() {
      return (
        <div className="paypal-payment">
            <h2>Payment</h2>
            <p></p>
            {this.state.url!==null&&<a target="_blank" data-paypal-onboard-complete="onboardedCallback" href={this.state.url} data-paypal-button="true">Sign Test</a>}
            {this.state.url!==null&&<a data-paypal-button="true" href={this.state.url} target="PPFrame">Sign up for PayPal</a>}
            <button onClick={this.addurl}>Click here</button>
            <button onClick={this.addurl2}>Click here2</button>
        </div>
      );
    }
  } 

export default Payment;