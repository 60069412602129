import React from 'react';
class EmbedCode extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            show:false
        };
    }


    render(){
        return(
            <div className="element-editor">
               <div className="remove" style={{visibility: this.state.show ? 'visible' : 'hidden' }}>
                </div>
                <div className="imagen-element">
                    <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
                </div>
            </div>
        );
    }

}




export default EmbedCode;