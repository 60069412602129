import React from 'react';
import { mdiDotsVertical , mdiPlus} from '@mdi/js';
class EmbedLink extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            show:false
        };
    }

    render(){
        return(
            <div className="element-editor">
                <div></div>
                <div className="imagen-element">
                    <iframe className="preview-image" width="100%" height="400" src={this.props.content}>
                    </iframe>
                </div>
            </div>
        );
    }

}




export default EmbedLink;