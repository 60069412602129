import React, { Component } from 'react';
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js';

//const firebaseApp = firebase.initializeApp(firebaseConfig);

class ModalRestrictions extends Component {

  constructor(props){
    super(props); 

    this.state = [

    ];
  }
  closeModal= ()  => {
    this.props.showRestricModal(false)
  }

  goToPricePage= ()  => {
    this.closeModal()
    window.open("https://app.copack.io/pricing", "_blank")
  }
    render() {
      return (
        <div className="modal-container-restrict">

        <div className="restrict-modal">
            <div className="modal-close">
              <Icon color="#342e37" onClick={this.closeModal} path={mdiClose}/>
            </div>
            {
              <h3>Oops!</h3>
            }
            <div className="restrict-modal-body">
            <h4>This  feature is available only in the premium version. Buy a subscription to get this feature and many more. </h4>
              <ul>
                <li>1 user license</li>
                <li>Unlimited webcam and screen recording up to 1GB per user</li>
                <li>Video recording up to 30 minutes</li>
                <li>Unlimited paragraph creation and adding modules</li>
                <li>Lead generation (private pages)</li>
                <li>24/7 support</li>
                <li>Add payment gateway for your users (coming soon)</li>
              </ul>
            </div>
            <button onClick={this.goToPricePage} > get premium account</button>
        </div>
        
        
        </div>
      );
    }
  } 

export default ModalRestrictions;