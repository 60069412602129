import React from 'react';

class Preload extends React.Component{
    
    render(){
        return(
            <div className="preload">
                <img src={require('../Assets/preload.gif')} alt="img"></img>
            </div>
            
        );
    }

}




export default Preload;