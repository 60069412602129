import React from 'react';


class EditorTexto extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            show:false,
            showopc:false,
            text:""
        };
    }

    hiden(e){
        e.preventDefault()
        console.log("dale ramón");
    }

    onChangeText(e){
        let val = e.target.value;
        this.setState({
            text:val,
        })
    }

    addText(e){
        e.preventDefault()
        this.props.addText(this.state.text);
        

    }

    render(){
        return(
            <div className='editor-body-text'>
                <div className='editor-text'>
                    <div className='url-text' onClick={(e) => this.hiden(e)}>
                        <img src={require('../Assets/url.png')}></img>
                        <span>Link</span>
                    </div>
                </div>
                
                <div className='url-text-content'>
                    <input type='text' value={this.state.text} onChange={(e) => this.onChangeText(e)}></input>
                    <button onClick={(e) => this.addText(e)}>Add</button>
                </div>

            </div>
            

        )

    }



}


export default EditorTexto;