import React, { Component } from 'react';
import moment from 'moment'
import axios from 'axios';

//const firebaseApp = firebase.initializeApp(firebaseConfig);

class BodyLeads extends Component {

    constructor(props){
      super(props); 

      this.state = {
        leads: []
      }
    }

    componentDidMount() {
      const data ={
          uid : localStorage.getItem('uid')
      }
      axios.post(process.env.REACT_APP_API_HOST+'getleads',data)
      .then(res => {
          console.log(res)
          this.setState({leads: res.data});
      })
    }

    renderLeads = () => {
      if(this.state.leads.length === 0) return  <td colSpan="3" style={ {textAlign:"center"}}>No records</td>

      const Leads = this.state.leads.map((value,index) => {
          return <tr>
              <td>{value.email}</td>
              <td>{value.project_name}</td>
              <td>{moment(value.createdAt).format('YYYY-DD-MM')}</td>
          </tr>
      })

      return Leads;
    }
    
    dowloadLeads = () => {

      if(this.state.leads.length>0){
        window.open(process.env.REACT_APP_API_HOST + 'downloadLeads/' + localStorage.getItem('uid'))
      }
      
    }

    render() {
      return (
        <div className="body-leads">
            <h2>Leads</h2>
            <div className="content-download"><button onClick={this.dowloadLeads}>Download</button></div>
            <div className="content-table-leads"><table className="table-leads" responsive hever >
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Project</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderLeads()}
                </tbody>
            </table></div>
        </div>
      );
    }
  } 

export default BodyLeads;