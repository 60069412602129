import React from 'react';
import CookieBot from 'react-cookiebot';

class Home extends React.Component{
    
  


    render(){



    

        return (
            <div>
              <CookieBot domainGroupId={'69056810-5ec3-4949-a350-109bd451c9b4'} />
              <div className="navbar --fixed shadow">
                <div className="container">
                  <div className="col-3 md-5 sm-3 vertical--center">
                    <div className="logo"><a href="index.html"><img className="only-logo" src="img/logo_copack.png" alt="" style={{maxWidth: '210px'}} /><img className="only-icon" style={{width: '45px'}} src="img/favicon_bumper.png" alt="" /></a></div>
                  </div>
                  <div className="col-9 md-7 sm-9 menu-mobile v--center justify--right" id="main-menu">
                    <ul className="menu">
                      <li><a id="customLogin" href="/login"><span>Login</span></a></li>
                      <li><a id="customStart" className="btn --primary --sm" href="/signup"><span>Sign Up</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="section-header space-xl-top-inner">
                <div className="container text--center space-sm-bottom-inner">
                  <div className="col-2 sm-1 xs-12" />
                  <div className="col-8 sm-10 xs-12">
                    <h1 className="text--primary">All-in-one docs in one place,<br />reorder your mind and your team</h1><br />
                    <h6 className="lead">Create superdocs and embed your notes, tasks, wikis, and videos</h6><br /><a id="customStart2" className="btn --primary --lg" href="/login">Create your Docs now</a>
                  </div>
                  <div className="col-2 sm-1 xs-12" />
                </div>
                <div className="container">
                  <div className="col-12 no-line-height">
                    <div className="frames">
                      <div className="frame"><img src="img/sitedoo.gif" alt="" /></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-features space-xl-top-inner text--center bg--gray-lighter">
                <div className="container">
                  <div className="col-2 sm-1" />
                  <div className="col-8 sm-10 text--center">
                    <h1 className="text--primary">Centralize your documentation</h1>
                  </div>
                </div>
                <div className="container space-xl-inner space-md-top-inner">
                  <div className="col-4 sm-12">
                    <div className="featured-icon">
                      <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                        <path d="M6.72 20.492c1.532.956 3.342 1.508 5.28 1.508 1.934 0 3.741-.55 5.272-1.503l1.24 1.582c-1.876 1.215-4.112 1.921-6.512 1.921-2.403 0-4.642-.708-6.52-1.926l1.24-1.582zm6.405-.992l-.594.391c-.077.069-.19.109-.307.109h-.447c-.118 0-.231-.04-.308-.109l-.594-.391h2.25zm10.875-.5h-6c0-1.105.895-2 2-2h2c.53 0 1.039.211 1.414.586s.586.883.586 1.414zm-18 0h-6c0-1.105.895-2 2-2h2c.53 0 1.039.211 1.414.586s.586.883.586 1.414zm7.146-.5c.138 0 .25.112.25.25s-.112.25-.25.25h-2.279c-.138 0-.25-.112-.25-.25s.112-.25.25-.25h2.279zm.247-.5c0-2.002 1.607-2.83 1.607-4.614 0-1.86-1.501-2.886-3.001-2.886s-2.999 1.024-2.999 2.886c0 1.784 1.607 2.639 1.607 4.614h2.786zm7.607-6c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm-18 0c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm12.87 2.385l1.349.612-.413.911-1.298-.588c.15-.3.275-.608.362-.935zm-7.739 0c.087.332.208.631.36.935l-1.296.588-.414-.911 1.35-.612zm9.369-1.885v1h-1.501c.01-.335-.02-.672-.093-1h1.594zm-9.406 0c-.072.327-.102.663-.092.997v.003h-1.502v-1h1.594zm6.928-1.714l1.242-.882.579.816-1.252.888c-.146-.291-.335-.566-.569-.822zm-6.044-.001c-.23.252-.418.525-.569.823l-1.251-.888.578-.816 1.242.881zm4.435-1.046l.663-1.345.897.443-.664 1.345c-.278-.184-.58-.332-.896-.443zm-2.826-.001c-.315.11-.618.258-.897.442l-.663-1.343.897-.443.663 1.344zm-2.587-9.054v2.149c-2.938 1.285-5.141 3.942-5.798 7.158l-2.034-.003c.732-4.328 3.785-7.872 7.832-9.304zm8 0c4.047 1.432 7.1 4.976 7.832 9.304l-2.034.003c-.657-3.216-2.86-5.873-5.798-7.158v-2.149zm-3.5 8.846c-.334-.039-.654-.041-1-.001v-1.529h1v1.53zm2.5-2.53h-6c0-1.105.895-2 2-2h2c.53 0 1.039.211 1.414.586s.586.884.586 1.414zm-3-7c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                      </svg>
                    </div><br />
                    <h6 className="text--primary">Organize your docs in workspaces</h6>
                    <p>Add pages or subpages to organise your superdocs in the sidebar</p>
                  </div>
                  <div className="col-4 sm-12">
                    <div className="featured-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                        <path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" />
                      </svg>
                    </div><br />
                    <h6 className="text--primary">Create your superdocs</h6>
                    <p>Create superdocs with all the elements you need: images, videos, ...</p>
                  </div>
                  <div className="col-4 sm-12">
                    <div className="featured-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                        <path d="M6.689 5h10.622l.333 1h-11.289l.334-1zm-1 3h12.622l-.333-1h-11.957l-.332 1zm1.666-5l-.332 1h9.955l-.334-1h-9.289zm-3.248 5l2-6h11.785l2 6h2.108l-2.666-8h-14.668l-2.666 8h2.107zm17.419 11l-.644 3h-17.765l-.644-3h19.053zm2.474-2h-24l1.5 7h21l1.5-7zm-10-4h-4v1h4v-1zm0 7h-4v1h4v-1zm-10.883-5l-.644-3h19.053l-.644 3h2.046l1.072-5h-24l1.071 5h2.046z" />
                      </svg>
                    </div><br />
                    <h6 className="text--primary">Embed other docs</h6>
                    <p>Put together all the documentation of your enterprise in a single place</p>
                  </div>
                </div>
              </div>
              <div className="section-feature space-md--top-inner space-lg-bottom-inner bg--gray-lighter">
                <div className="container text--center-md">
                  <div className="col-5 md-12 vertical--center">
                    <h6 className="lead text--primary">Organise your mind</h6>
                    <h2>Create superdocs and get organized</h2><br />
                    <p>Wether you're looking to assemble the doc you've always dreamed of or that you're trying to centralize, this is the app for you</p>
                    <p>Copack allows to create super docs where you can centralize all your documents and informaton</p>
                  </div>
                  <div className="col-7 md-12">
                    <div className="wistia_responsive_padding" style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                      <div className="wistia_responsive_wrapper" style={{height: '100%', left: 0, position: 'absolute', top: 0, width: '100%'}}>
                        <iframe src="https://player.vimeo.com/video/413138442" title="What´s Copack Video" allowTransparency="true" frameBorder={0} scrolling="no" className="wistia_embed" name="wistia_embed" allowFullScreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%" />
                      </div>
                    </div>
                  </div>
                  <div className="col-3 sm-1" />
                </div>
              </div>
              <div className="section-main space-lg-inner">
                <div className="container">
                  <div className="col-2 sm-1" />
                  <div className="col-8 sm-10 text--center">
                    <h6 className="lead text--primary">User cases</h6>
                    <h2 className="hero2">Superdocs for your business cases</h2><br />
                    <p>Sales presentations, Meeting Notes, Resumes, To-do Lists, Product Feedback and more in a superdoc</p>
                  </div>
                  <div className="col-2 sm-1" />
                </div>
                <div className="container space-sm-top">
                  <div className="col-12">
                    <div className="featured-images text--center"><img className="feature-1" src="img/tool1.jpg" alt="" /><img className="feature-2" src="img/tool2.jpg" alt="" /><img className="feature-3" src="img/sitedoo.jpg" alt="" /></div>
                  </div>
                </div>
                <div className="container space-md-top">
                  <div className="col-3 sm-6 xs-12">
                    <div className="feature">
                      <div className="feature--icon">
                        <svg className="color--brown" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                          <path d="M5 9v8h-2v-8h2zm2-2h-6v12h6v-12zm6-4v14h-2v-14h2zm2-2h-6v18h6v-18zm6 13v3h-2v-3h2zm2-2h-6v7h6v-7zm1 9h-24v2h24v-2z" />
                        </svg>
                      </div>
                      <h6 className="feature--title color--brown">Project management</h6>
                      {/* <p class="feature--content">Manage and organize your project in stages creating lists, calendars and goals.</p> */}
                    </div>
                  </div>
                  <div className="col-3 sm-6 xs-12">
                    <div className="feature">
                      <div className="feature--icon">
                        <svg className="color--orange" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                          <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-4 7h-8v1h8v-1zm0 5h-8v1h8v-1zm0 5h-8v1h8v-1zm-10.516-11.304l-.71-.696-2.553 2.607-1.539-1.452-.698.71 2.25 2.135 3.25-3.304zm0 5l-.71-.696-2.552 2.607-1.539-1.452-.698.709 2.249 2.136 3.25-3.304zm0 5l-.71-.696-2.552 2.607-1.539-1.452-.698.709 2.249 2.136 3.25-3.304z" />
                        </svg>
                      </div>
                      <h6 className="feature--title color--orange">Task list</h6>
                      {/* <p class="feature--content">Quasi atque quam distinctio dolores ad vel earum mollitia fugiat neque sint officiis.</p> */}
                    </div>
                  </div>
                  <div className="col-3 sm-6 xs-12">
                    <div className="feature">
                      <div className="feature--icon">
                        <svg className="color--yellow" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                          <path d="M21.312 12.644c-.972-1.189-3.646-4.212-4.597-5.284l-1.784 1.018 4.657 5.35c.623.692.459 1.704-.376 2.239-.773.497-5.341 3.376-6.386 4.035-.074-.721-.358-1.391-.826-1.948-.469-.557-6.115-7.376-7.523-9.178-.469-.601-.575-1.246-.295-1.817.268-.549.842-.918 1.43-.918.919 0 1.408.655 1.549 1.215.16.641-.035 1.231-.623 1.685l1.329 1.624 7.796-4.446c1.422-1.051 1.822-2.991.93-4.513-.618-1.053-1.759-1.706-2.978-1.706-1.188 0-.793-.016-9.565 4.475-1.234.591-2.05 1.787-2.05 3.202 0 .87.308 1.756.889 2.487 1.427 1.794 7.561 9.185 7.616 9.257.371.493.427 1.119.15 1.673-.277.555-.812.886-1.429.886-.919 0-1.408-.655-1.549-1.216-.156-.629.012-1.208.604-1.654l-1.277-1.545c-.822.665-1.277 1.496-1.377 2.442-.232 2.205 1.525 3.993 3.613 3.993.596 0 1.311-.177 1.841-.51l9.427-5.946c.957-.664 1.492-1.781 1.492-2.897 0-.744-.24-1.454-.688-2.003zm-8.292-10.492c.188-.087.398-.134.609-.134.532 0 .997.281 1.243.752.312.596.226 1.469-.548 1.912l-5.097 2.888c-.051-1.089-.579-2.081-1.455-2.732l5.248-2.686zm3.254 10.055l-4.828 2.823-.645-.781 4.805-2.808.668.766zm-6.96.238l4.75-2.777.668.768-4.773 2.791-.645-.782zm8.49 1.519l-4.881 2.854-.645-.781 4.858-2.84.668.767z" />
                        </svg>
                      </div>
                      <h6 className="feature--title color--yellow">Wiki documentation</h6>
                      {/* <p class="feature--content">Quasi atque quam distinctio dolores ad vel earum mollitia fugiat neque sint officiis.</p> */}
                    </div>
                  </div>
                  <div className="col-3 sm-6 xs-12">
                    <div className="feature">
                      <div className="feature--icon">
                        <svg className="color--green" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                          <path d="M9 19h-4v-2h4v2zm2.946-4.036l3.107 3.105-4.112.931 1.005-4.036zm12.054-5.839l-7.898 7.996-3.202-3.202 7.898-7.995 3.202 3.201zm-6 8.92v3.955h-16v-18h4l2.102 2h3.898l2-2h4v1.911l2-2.024v-1.887h-3c-1.229 0-2.18-1.084-3-2h-8c-.82.916-1.771 2-3 2h-3v22h20v-7.98l-2 2.025zm-8-16.045c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
                        </svg>
                      </div>
                      <h6 className="feature--title color--green">Meeting Notes</h6>
                      {/* <p class="feature--content">Quasi atque quam distinctio dolores ad vel earum mollitia fugiat neque sint officiis.</p>*/}
                    </div>
                  </div>
                  <div className="col-3 sm-6 xs-12">
                    <div className="feature">
                      <div className="feature--icon">
                        <svg className="color--purple" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                          <path d="M15.744 16.683l.349-.199v1.717l-.349.195v-1.713zm3.414-.227l.342-.196v-1.717l-.343.195v1.718zm-1.429.813l.343-.195v-1.717l-.343.195v1.717zm.578-.329l.349-.199v-1.717l-.349.199v1.717zm-1.152.656l.343-.196v-1.717l-.343.196v1.717zm-.821.467l.343-.195v-1.717l-.343.195v1.717zm6.666-11.122v11.507l-9.75 5.552-12.25-6.978v-11.507l9.767-5.515 12.233 6.941zm-12.236-4.643l-2.106 1.19 8.891 5.234-.002.003 2.33-1.256-9.113-5.171zm1.236 10.59l-9-5.218v8.19l9 5.126v-8.098zm3.493-3.056l-8.847-5.208-2.488 1.405 8.86 5.138 2.475-1.335zm5.507-.696l-7 3.773v8.362l7-3.985v-8.15z" />
                        </svg>
                      </div>
                      <h6 className="feature--title color--purple">Product Guide</h6>
                      {/* <p class="feature--content">Quasi atque quam distinctio dolores ad vel earum mollitia fugiat neque sint officiis.</p>*/}
                    </div>
                  </div>
                  <div className="col-3 sm-6 xs-12">
                    <div className="feature">
                      <div className="feature--icon">
                        <svg className="color--blue" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                          <path d="M23.948.042c-.413-.028-.817-.042-1.214-.042-8.6 0-13.497 6.557-15.278 11.833l4.727 4.727c5.428-1.944 11.817-6.66 11.817-15.168 0-.44-.017-.89-.052-1.35zm-11.277 14.178l-2.883-2.883c1.221-2.859 4.691-8.945 12.199-9.32-.251 5.775-4.041 9.932-9.316 12.203zm5.471 1.538c-.547.373-1.09.71-1.628 1.011-.187.891-.662 1.842-1.351 2.652-.002-.576-.162-1.156-.443-1.738-.495.225-.966.418-1.414.588.66 1.709-.012 2.971-.915 4.154 1.296-.098 2.656-.732 3.728-1.805 1.155-1.155 1.967-2.823 2.023-4.862zm-11.82-6.469c-.579-.28-1.158-.438-1.732-.441.803-.681 1.744-1.153 2.626-1.345.314-.552.667-1.097 1.039-1.633-2.039.055-3.708.867-4.864 2.023-1.071 1.071-1.706 2.433-1.804 3.728 1.184-.904 2.446-1.576 4.155-.914.173-.471.366-.944.58-1.418zm7.738.663c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0c.391.392.391 1.024 0 1.415s-1.024.39-1.414-.001zm4.949-4.951c-.78-.78-2.047-.78-2.828 0-.781.781-.781 2.049 0 2.829.781.781 2.048.781 2.829 0 .78-.78.78-2.047-.001-2.829zm-1.908 1.911c-.273-.273-.273-.718 0-.99.271-.273.717-.273.99 0 .272.272.271.717 0 .99-.274.272-.718.272-.99 0zm-6.747 10.65c-1.492 3.81-5.803 6.208-10.354 6.438.219-4.289 2.657-8.676 6.64-10.153l.805.806c-4.331 2.755-4.653 5.346-4.665 6.575 1.268-.015 4.054-.344 6.778-4.464l.796.798z" />
                        </svg>
                      </div>
                      <h6 className="feature--title color--blue">Branding Styleguide</h6>
                      {/* <p class="feature--content">Quasi atque quam distinctio dolores ad vel earum mollitia fugiat neque sint officiis.</p> */}
                    </div>
                  </div>
                  <div className="col-3 sm-6 xs-12">
                    <div className="feature">
                      <div className="feature--icon">
                        <svg className="color--pink" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                          <path d="M24 2v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm1 11.729l.855-.791c1 .484 1.635.852 2.76 1.654 2.113-2.399 3.511-3.616 6.106-5.231l.279.64c-2.141 1.869-3.709 3.949-5.967 7.999-1.393-1.64-2.322-2.686-4.033-4.271z" />
                        </svg>
                      </div>
                      <h6 className="feature--title color--pink">Enterprise Calendar</h6>
                      {/* <p class="feature--content">Quasi atque quam distinctio dolores ad vel earum mollitia fugiat neque sint officiis.</p> */}
                    </div>
                  </div>
                  <div className="col-3 sm-6 xs-12">
                    <div className="feature">
                      <div className="feature--icon">
                        <svg className="color--red" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm8.975 7.617l-2.607 1.485c-.697-1.53-1.928-2.762-3.455-3.462l1.484-2.608c1.988.979 3.601 2.595 4.578 4.585zm-8.975 9.383c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zm-4.397-13.968l1.485 2.608c-1.527.701-2.757 1.933-3.455 3.463l-2.608-1.486c.976-1.99 2.59-3.606 4.578-4.585zm-4.578 13.351l2.608-1.485c.697 1.53 1.927 2.762 3.455 3.462l-1.485 2.608c-1.988-.979-3.602-2.595-4.578-4.585zm13.371 4.585l-1.484-2.608c1.527-.701 2.758-1.933 3.455-3.462l2.607 1.485c-.976 1.99-2.589 3.606-4.578 4.585z" />
                        </svg>
                      </div>
                      <h6 className="feature--title color--red">Product Feedback</h6>
                      {/* <p class="feature--content">Quasi atque quam distinctio dolores ad vel earum mollitia fugiat neque sint officiis.</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-elements space-lg-inner space-xl-bottom-inner">
                <div className="container">
                  <div className="col-5 sm-12 vertical--center">
                    <h6 className="lead text--primary">And embed 20+ elements in your superdoc</h6>
                    <h2 className="hero2">Google Drive, Google Docs, Airtable, Trello, Typeform, Youtube, Videos, and more</h2>
                    {/*<p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Curabitur blandit tempus porttitor.</p>*/}
                  </div>
                  <div className="col-7 sm-12">
                    <div className="container sitedoo-elements">
                      <div className="sitedoo-element"><img src="img/elem-p.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Text element</h6>
                          </div>
                          <div className="elem-description">
                            {/*<p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p>*/}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-h1.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Header</h6>
                          </div>
                          <div className="elem-description">
                            {/*<p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p>*/}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-h2.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Subheader</h6>
                          </div>
                          <div className="elem-description">
                            {/*<p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p>*/}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-ul.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Bullets</h6>
                          </div>
                          <div className="elem-description">
                            {/*<p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p>*/}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-ol.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Numbers</h6>
                          </div>
                          <div className="elem-description">
                            {/*<p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p>*/}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-todo.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Todo</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-toggle.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Toggle</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-quote.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Quote</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-code.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Code</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-line.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Separator</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-link-to-page.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Page Links</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-bookmark.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Bookmark</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-file.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Files</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-image.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Images</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-gallery.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Gallery</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-video.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Videos</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-embed.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Embeds</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-paste-code.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Iframes</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-drive.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Google Drive</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-docs.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Google Docs</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-spreadsheets.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Google Spreadsheets</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-presenter.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Google Presenter</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-calendar.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Google Calendar</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-youtube.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Youtube</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-mofu.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Mofuvideo</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-trello.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Trello</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-typeform.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Typeform</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="sitedoo-element"><img src="img/elem-airtable.png" alt="" />
                        <div className="elem-container">
                          <div className="elem-title">
                            <h6>Airtable</h6>
                          </div>
                          <div className="elem-description">
                            {/* <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-use text--center space-lg-inner bg--primary">
                <div className="container">
                  <div className="col-3 sm-12" />
                  <div className="col-6 space-sm-bottom sm-12">
                    <h6 className="lead text--white">They are already using Copack</h6>
                    <h2 className="text--white">Docs with steroids created using Copack</h2>
                  </div>
                  <div className="col-3 sm-12" />
                </div>
                <div className="container clients space-sm-top"><img className="user-logo" src="img/logo1.png" alt="" /><img className="user-logo" src="img/logo2.png" alt="" /><img className="user-logo" src="img/logo3.png" alt="" /><img className="user-logo" src="img/logo4.png" alt="" /><img className="user-logo" src="img/logo5.png" alt="" /><img className="user-logo" src="img/logo6.png" alt="" /><img className="user-logo" src="img/logo7.png" alt="" /></div>
              </div>
              <div className="section-faqs space-md-inner bg--primary">
                <div className="container faqs--container">
                  <div className="col-12">
                    <div className="space-sm-top space-md-bottom">
                      <h2 className="text--primary text--center">Frequent questions</h2>
                    </div>
                    <div className="container">
                      <div className="col-6 xs-12">
                        <div className="faq">
                          <div className="question">
                            <div className="question--icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                              </svg>
                            </div>
                            <div className="question--content">
                              <h6 className="question--title">What can I do with Copack?</h6>
                              <p className="question--answer">With Copack you can organize your information in a quick and easy way, you can create manuals for users, documentation, information, etc.</p>
                            </div>
                          </div>
                        </div>
                        <div className="faq">
                          <div className="question">
                            <div className="question--icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                              </svg>
                            </div>
                            <div className="question--content">
                              <h6 className="question--title">Can I use Copack for my company?</h6>
                              <p className="question--answer">Of course, create your account using your Gmail account and login to start creating documents for your company.</p>
                            </div>
                          </div>
                        </div>
                        <div className="faq">
                          <div className="question">
                            <div className="question--icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                              </svg>
                            </div>
                            <div className="question--content">
                              <h6 className="question--title">Can I create a webpage with Copack?</h6>
                              <p className="question--answer">Absolutely, use a doc as a mega doc and share your budget, proposal, site or another document.</p>
                            </div>
                          </div>
                        </div>
                        <div className="faq">
                          <div className="question">
                            <div className="question--icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                              </svg>
                            </div>
                            <div className="question--content">
                              <h6 className="question--title">Can I share my docs?</h6>
                              <p className="question--answer">Yes, use the share button in the editor and send the link to your contacts.</p>
                            </div>
                          </div>
                        </div>
                        <div className="faq">
                          <div className="question">
                            <div className="question--icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                              </svg>
                            </div>
                            <div className="question--content">
                              <h6 className="question--title">Can I upload videos with Copack?</h6>
                              <p className="question--answer">Yes, you can upload or record your own videos and embed in your document.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 xs-12">
                        <div className="faq">
                          <div className="question">
                            <div className="question--icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                              </svg>
                            </div>
                            <div className="question--content">
                              <h6 className="question--title">Is it easy to create a document with Copack?</h6>
                              <p className="question--answer">Very easy, use Slack shortcuts to create the elements and navigate in the document and embed all the documents you need.</p>
                            </div>
                          </div>
                        </div>
                        <div className="faq">
                          <div className="question">
                            <div className="question--icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                              </svg>
                            </div>
                            <div className="question--content">
                              <h6 className="question--title">Can I upload images to Copack?</h6>
                              <p className="question--answer">With Copack you can organize your information in a quick and easy way, you can create manuals for users, documentation, information, etc.</p>
                            </div>
                          </div>
                        </div>
                        <div className="faq">
                          <div className="question">
                            <div className="question--icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                              </svg>
                            </div>
                            <div className="question--content">
                              <h6 className="question--title">How many collaborators can I have in Copack?</h6>
                              <p className="question--answer">Currently is not available but invite options will be available soon. Keep connected and follow us to know new releases.</p>
                            </div>
                          </div>
                        </div>
                        <div className="faq">
                          <div className="question">
                            <div className="question--icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                              </svg>
                            </div>
                            <div className="question--content">
                              <h6 className="question--title">How many pages can I have in Copack?</h6>
                              <p className="question--answer">With Copack you can organise all your documents under other documents and have as many you need.</p>
                            </div>
                          </div>
                        </div>
                        <div className="faq">
                          <div className="question">
                            <div className="question--icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                              </svg>
                            </div>
                            <div className="question--content">
                              <h6 className="question--title">Is there a Copack app?</h6>
                              <p className="question--answer">You don't need to use an app, you can use the mobile app and access directly from your favorite browser as Google Chrome or Safari.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-start-now space-md-inner space-lg-bottom-inner">
                <div className="container text--center">
                  <div className="col-3 sm-1" />
                  <div className="col-6 sm-10">
                    <h6 className="lead">SUPERDOCS FOR YOUR NEEDS</h6>
                    <h2>Create and share the best documentation</h2><br /><a className="btn --secondary --lg" href="/login" id="customStart3">Create a superdoc now</a>
                  </div>
                  <div className="col-3 sm-1" />
                </div>
              </div>
              <footer className="main-footer">
                <div className="container">
                  <div className="col-6 xs-12 text--center-xs"><img className="logo" src="img/logo_copack.png" alt="" style={{width: '210px'}} />
                    <p className="slogan">Superdocs to organise your mind</p>
                  </div>
                  <div className="col-6 xs-12 text--right text--center-xs">
                    <ul className="social-icons">
                      {/*<li><a className="icon-facebook" href="https://facebook.com/sitedoo" /></li>
                      <li><a className="icon-twitter" href="https://twitter.com/sitedoo_app" /></li>*/}
                      {/* <li><a class="icon-instagram" href=""></a></li>
                  <li><a class="icon-google-plus" href=""></a></li>
                  <li><a class="icon-linkedin" href=""></a></li> */}
                    </ul>
                  </div>
                </div>
              </footer>
              <div className="after-footer">
                <div className="container">
                  <div className="col-12 xs-12 text--center-xs"><a href="/privacy-policy">Privacy Policy</a></div>
                  <div className="col-12 xs-12 text--center-xs"><a href="/cookies">Cookie Policies</a></div>
                  <div className="col-6 xs-12 text--center-xs"><a className="contact-us" href>support@Copack.com</a></div>
                  <div className="col-6 xs-12 text--right text--center-xs">
                    <p className="copyright">Crafted lovingly with Copack</p>
                  </div>
                </div>
              </div>
            </div>
          );
    }

}




export default Home;