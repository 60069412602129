import React from 'react';
class Image extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            show:false
        };
    }



    render(){
        return(
            <div className="element-editor">
               <div className="remove">
                </div>
                <div className="imagen-element">
                    <iframe className="preview-image" width="100%" height="400" src={this.props.content}>
                    </iframe>
                </div>
            </div>
        );
    }

}




export default Image;