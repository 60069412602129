import React from 'react';
import ReactTooltip from 'react-tooltip';
import Image from './Image';
import PreImage from './PreImage';
import EmbedVideo from './EmbedVideo';
import PreEmbedVideo from './PreEmbedVideo';
import File from './File';
import PreFile from './PreFile';
import Youtube from './Youtube';
import PreYoutube from './PreYoutube';
import GoogleDocs from './GoogleDocs';
import PreGoogleDocs from './PreGoogleDocs';
import PreEmbedLink from './PreEmbedLink';
import EmbedLink from './EmbedLink';
import Separator from './Separator'; 
import Video from './Video';
import PreFileVideo from './PreFileVideo';
import PreEmbedCode from './PreEmbedCode';
import EmbedCode from './EmbedCode';
import Cover from './Cover';
import Text from './Text';
import axios from 'axios';
import 'emoji-mart/css/emoji-mart.css'
import { Emoji , Picker } from 'emoji-mart'
import Header from './Header';
class Editor extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
                background:"bg1.jpg",
                showemoji:false,
                posCover:0,
                elements:[]

        };
    }


    componentDidMount() {
        
        const data ={
            uid : localStorage.getItem('uid'),
            page: this.props.page
        }
        axios.post(process.env.REACT_APP_API_HOST+'getdata',data)
        .then(res => {
            const sz = res.data.elements.length;
            for(var i = 0; i<sz;i++){
                if(res.data.elements[i].type==="PreEmbedVideo"){
                    res.data.elements[i].type = "Text";
                }
                res.data.elements[i].show = false;
                res.data.elements[i].focus = false;
            }
            this.setState(res.data);
        })
    }

    componentDidUpdate(){
        if(this.nameInput!==undefined){
            if(this.nameInput!==null){
                this.nameInput.focus();
                console.log('Entre auqi');
            }
            
        }

        const sz = this.state.elements.length;

        for(var i = 0; i<sz;i++){
            if(this.state.elements[i].type==="PreEmbedVideo"){
                this.state.elements[i].type = "PreEmbedVideo";      
                this.state.elements[i].show = false;
                this.state.elements[i].focus = false;
                this.state.elements[i].subtype = "recording";   
            }

        }
        if(parseInt(this.props.page, 10)!==this.state.id){
            
            const data ={
                uid : localStorage.getItem('uid'),
                page: this.props.page
            }
            axios.post(process.env.REACT_APP_API_HOST+'getdata',data)
            .then(res => {
                const sz = res.data.elements.length;
                for(var i = 0; i<sz;i++){
                    if(res.data.elements[i].type==="PreEmbedVideo"){
                        res.data.elements[i].type = "Text";
                    }

                    res.data.elements[i].show = false;
                    res.data.elements[i].focus = false;
                }
                if(res.data.id===parseInt(this.props.page)){
                    this.setState(res.data);
                }
                
            })
        }else{


            let x = this.state;



            this.sleep(300).then(() => {


                if(x===this.state){
                    
                    
                    /*console.log('son iguales');
                    const socket = socketIOClient(process.env.REACT_APP_API_HOST,{ transports: ['websocket', 'polling'] });
                    const data = {
                        data : this.state,
                        url :  localStorage.getItem('uid')+"/default/"+this.props.page
                    }
                    socket.emit("update-info-editor",data);*/


                    if(parseInt(this.props.page, 10)===this.state.id){
                    const params = {
                        'data': this.state,
                        'url': localStorage.getItem('uid')+"/default/"+this.props.page
                     };

                      axios.post(process.env.REACT_APP_API_HOST+"updateinfo", params, { // receive two parameter endpoint url ,form data 
                        
                          headers: {
                              'content-type': 'application/json',
                          },
                    
                      })
                      .then(res => { // then print response status
              
                          if(res.statusText==="OK"){
                          }
              
                      })

                    }
              

                }else{
                }
            
            })
            
            
        }

        
    }


    updateVideo(id_page,index,val){


        const data ={
            uid : localStorage.getItem('uid'),
            page: id_page
        }
        axios.post(process.env.REACT_APP_API_HOST+'getdata',data)
        .then(res => {
            
            let x = res.data;

            x.elements[index].content = val;
            x.elements[index].type = "EmbedVideo";


            const params = {
                'data': x,
                'url': localStorage.getItem('uid')+"/default/"+id_page
            };

            axios.post(process.env.REACT_APP_API_HOST+"updateinfo", params, { // receive two parameter endpoint url ,form data 
            
                headers: {
                    'content-type': 'application/json',
                },
        
            })
            .then(res => { // then print response status
    
                if(res.statusText==="OK"){
                    
                }
    
            })


        })

    }

    handleParent(e) {
        const sz = this.state.elements.length;

        if(sz===0){
            let a = this.state.elements.slice(); //creates the clone of the state
            a[sz] = {type:"Text",content:"",focus:true};
            this.setState({focus:false});
            this.setState({elements: a});
            return
        }

        if(this.state.elements[sz-1].type!=="Text" && this.state.elements[sz-1].type!=="Header" && this.state.elements[sz-1].type!=="SubHeader"){
            let a = this.state.elements.slice(); //creates the clone of the state
            this.setState({focus:false});
            a[sz] = {type:"Text",content:"",focus:true};
            this.setState({elements: a});
            return;
        }

        if(this.state.elements[sz-1].content.replace(/\s/g, '')===''){
            var stateCopy = Object.assign({}, this.state);
            stateCopy.elements[sz-1].focus = true;
            stateCopy.focus = false;
            this.setState(stateCopy);
        }else{
            let a = this.state.elements.slice(); //creates the clone of the state
            this.setState({focus:false});
            a[sz] = {type:"Text",content:"",focus:true};
            this.setState({elements: a});
        }
    }
    
    handleChild(e) {
        e.stopPropagation();   
    }

    selectItem = (value,index) => {
        var stateCopy = Object.assign({}, this.state);
        if(value.name==="Image"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreImage";
        }
        if(value.name==="Header"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = true;
            stateCopy.elements[index].type = "Header";
        }
        if(value.name==="Subheader"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = true;
            stateCopy.elements[index].type = "SubHeader";
        }

        if(value.name==="Text"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = true;
            stateCopy.elements[index].type = "Text";
        }

        if(value.name==="File"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreFile";
        }

        if(value.name==="Youtube"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreYoutube";
        }

        if(value.name==="Record video"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedVideo";
            stateCopy.elements[index].subtype = "Micro";
        }
        if(value.name==="Record Video + Cam"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedVideo";
            stateCopy.elements[index].subtype = "Castcam";
        }

        if(value.name==="Google Drive"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreGoogleDocs";
        }

        if(value.name==="Embed Link" || value.name==="Typeform" ){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedLink";
        }

        if(value.name==="Divider"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "Divider";
        }

        if(value.name==="Video"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreFileVideo";
        }

        if(value.name==="Embed Code"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedCode";
        }



        this.setState(stateCopy);
    }


    changeToImage = (name,index) => {

        var stateCopy = Object.assign({}, this.state);
        stateCopy.elements[index].content = name;
        stateCopy.elements[index].type = "Image";
        this.setState(stateCopy);
        
    }

    changeToVideoFile= (name,index) => {

        const val = name.originalname.split("-")[0]+"/"+name.originalname;
        const size = Math.round(name.size/1024);

        var stateCopy = Object.assign({}, this.state);
        stateCopy.elements[index].content = val;
        stateCopy.elements[index].size = size;
        stateCopy.elements[index].type = "Video";
        this.setState(stateCopy);
        
    }

    changeToFile = (name,index) => {

        const val = name.originalname.split("-")[0]+"/"+name.originalname;
        const size =Math.round(name.size/1024);

        var stateCopy = Object.assign({}, this.state);
        stateCopy.elements[index].content = val;
        stateCopy.elements[index].size = size;
        stateCopy.elements[index].type = "File";
        this.setState(stateCopy);
        
    }

    changeToVideo = (name,index,page_id) => {

        console.log(page_id+" "+this.props.page);

        const val = name;

        if(page_id===this.props.page){

            
            var stateCopy = Object.assign({}, this.state);
            stateCopy.elements[index].content = val;
            stateCopy.elements[index].type = "EmbedVideo";
            this.setState(stateCopy);

        }else{

            this.updateVideo(page_id,index,val);
     
        }

 
        
    }

    changeToYoutube = (name,index) => {

        var youtubeRegExp = /(?:[?&]vi?=|\/embed\/|\/\d\d?\/|\/vi?\/|https?:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/;
        var match = name.match( youtubeRegExp );
        var val = "https://www.youtube.com/embed/";
        if( match && match[ 1 ].length === 11 ) {
            val = val+match[1];
            
        }
        var stateCopy = Object.assign({}, this.state);
        stateCopy.elements[index].content = val;
        stateCopy.elements[index].type = "Youtube";
        this.setState(stateCopy);
        
    }


    changeToEmbedLink = (name,index) => {

        var youtubeRegExp = /(?:[?&]vi?=|\/embed\/|\/\d\d?\/|\/vi?\/|https?:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/;
        var match = name.match( youtubeRegExp );
        var val = "https://www.youtube.com/embed/";
        if( match && match[ 1 ].length === 11 ) {
            name = val+match[1];
            
        }

        var stateCopy = Object.assign({}, this.state);
        stateCopy.elements[index].content = name;
        stateCopy.elements[index].type = "EmbedLink";
        this.setState(stateCopy);
    }

    changeToEmbedCode = (name,index) => {

        var stateCopy = Object.assign({}, this.state);
        stateCopy.elements[index].content = name;
        stateCopy.elements[index].type = "EmbedCode";
        this.setState(stateCopy);
    }

    changeToGoogle = (name,index) => {

        var stateCopy = Object.assign({}, this.state);
        stateCopy.elements[index].content = name;
        stateCopy.elements[index].type = "GoogleDocs";
        this.setState(stateCopy);
        
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    removeItem = (index) =>{
        const sz = this.state.elements.length;
        var stateCopy = Object.assign({}, this.state);
        for(var i = 0; i<sz;i++){
            stateCopy.elements[i].focus=false;
        }
        stateCopy.elements.splice(index,1);
        this.setState(stateCopy);
    }

    duplicateItem = (index) =>{
        const sz = this.state.elements.length;
        var stateCopy = Object.assign({}, this.state);
        for(var i = 0; i<sz;i++){
            stateCopy.elements[i].focus=false;
        }
        stateCopy.elements.splice(index+1,0,this.state.elements[index]);
        this.setState(stateCopy);   
    }

    changeStateProps = (value) =>{
        this.setState(value);
        
    }

    changeTitle = (value) =>{  

        const sz = this.state.elements.length;
        var stateCopy = Object.assign({}, this.state);
        stateCopy.name = value;
        for(var i = 0; i<sz;i++){
            stateCopy.elements[i].focus=false;
        }
        this.setState(stateCopy);

        this.props.updateTitle(value,this.state.id);
    }

    selectMyText = (index) =>{

       

        const sz = this.state.elements.length;
        var stateCopy = Object.assign({}, this.state);
        if(stateCopy.elements[index].focus) return;
        for(var i = 0; i<sz;i++){
            stateCopy.elements[i].focus=false;
        }

        stateCopy.elements[index].focus = true;

        this.setState(stateCopy);


    }

    changeSize = (index,style) => {



        var stateCopy = Object.assign({}, this.state);
        stateCopy.elements[index].css = style;
        stateCopy.elements[index].type = "Image";
        this.setState(stateCopy);


    }

    changeSizeYoutube = (index,style) => {



        var stateCopy = Object.assign({}, this.state);
        stateCopy.elements[index].css = style;
        stateCopy.elements[index].type = "Youtube";

        this.setState(stateCopy);


    }


    renderElements = (props) => {


        const Element = props.state.elements.map((value,index) => {

            if(value.type==="Text" || value.type==="Header" || value.type==="SubHeader"){
 
                return <Text showRestricModal={this.props.showRestricModal} selectMyText={this.selectMyText}  update={this.updatePropsChild} showelement={value.showelement} type={value.type} state={this.state} changeState={this.changeStateProps} index={index} content={value.content} focus={value.focus} remove={this.removeItem} duplicate={this.duplicateItem} show={value.show}></Text>
            }

            if(value.type==="Image"){
                return <Image  showRestricModal={this.props.showRestricModal} state={this.state} changeSize={this.changeSize} changeState={this.changeStateProps} update={this.updatePropsChild} content={value.content} css={value.css} remove={this.removeItem} index={index} duplicate={this.duplicateItem} show={value.show}></Image>
            }

            if(value.type==="PreImage"){
                return <PreImage showRestricModal={this.props.showRestricModal} changeTo={props.changeToImage} index={index} show={value.show} remove={this.removeItem} duplicate={this.duplicateItem}></PreImage>
            }
            if(value.type==="EmbedVideo"){
                return <EmbedVideo showRestricModal={this.props.showRestricModal} state={this.state} changeState={this.changeStateProps}  update={this.updatePropsChild} show={value.show} content={value.content} remove={this.removeItem} index={index} duplicate={this.duplicateItem}></EmbedVideo>
            }
            if(value.type==="PreEmbedVideo"){
                return <PreEmbedVideo showRestricModal={this.props.showRestricModal} show={value.show}  type={value.subtype} changeTo={props.changeToVideo} currentPage={this.props.page} index={index}></PreEmbedVideo>
            }
            if(value.type==="File"){
                return <File showRestricModal={this.props.showRestricModal} state={this.state} changeState={this.changeStateProps} update={this.updatePropsChild} show={value.show} content={value.content} size={value.size} remove={this.removeItem} index={index} duplicate={this.duplicateItem}></File>
            }
            if(value.type==="PreFile"){
                return <PreFile showRestricModal={this.props.showRestricModal} show={value.show} changeTo={props.changeToFile} index={index} remove={this.removeItem} duplicate={this.duplicateItem}></PreFile>
            } 

            if(value.type==="Video"){
                return <Video showRestricModal={this.props.showRestricModal} state={this.state} changeState={this.changeStateProps} update={this.updatePropsChild} show={value.show} content={value.content} size={value.size} remove={this.removeItem} index={index} duplicate={this.duplicateItem}></Video>
            }
            if(value.type==="PreFileVideo"){
                return <PreFileVideo showRestricModal={this.props.showRestricModal} show={value.show} changeTo={props.changeToVideoFile} index={index} remove={this.removeItem} duplicate={this.duplicateItem}></PreFileVideo>
            } 

            if(value.type==="PreYoutube"){
                return <PreYoutube showRestricModal={this.props.showRestricModal} show={value.show} changeTo={props.changeToYoutube} index={index}  remove={this.removeItem} duplicate={this.duplicateItem} ></PreYoutube>
            }
            if(value.type==="Youtube"){
                return <Youtube showRestricModal={this.props.showRestricModal} state={this.state} changeSizeYoutube={this.changeSizeYoutube}  changeState={this.changeStateProps} update={this.updatePropsChild} show={value.show} content={value.content} css={value.css} remove={this.removeItem} index={index} duplicate={this.duplicateItem}></Youtube>
            }
            
            if(value.type==="PreGoogleDocs"){
                return <PreGoogleDocs showRestricModal={this.props.showRestricModal}  show={value.show} gleDocs changeTo={props.changeToGoogle} index={index} remove={this.removeItem} duplicate={this.duplicateItem}></PreGoogleDocs>
            }

            if(value.type==="GoogleDocs"){
                return <GoogleDocs showRestricModal={this.props.showRestricModal} state={this.state} changeState={this.changeStateProps} update={this.updatePropsChild} show={value.show} content={value.content} remove={this.removeItem} index={index} duplicate={this.duplicateItem}></GoogleDocs>
            }


            if(value.type==="PreEmbedLink"){
                return <PreEmbedLink showRestricModal={this.props.showRestricModal} show={value.show} changeTo={props.changeToEmbedLink} index={index} remove={this.removeItem} duplicate={this.duplicateItem} ></PreEmbedLink>
            }

            if(value.type==="EmbedLink"){
                return <EmbedLink showRestricModal={this.props.showRestricModal} state={this.state} changeState={this.changeStateProps} update={this.updatePropsChild} show={value.show} content={value.content} remove={this.removeItem} index={index} duplicate={this.duplicateItem}></EmbedLink>
            }

            if(value.type==="PreEmbedCode"){
                return <PreEmbedCode showRestricModal={this.props.showRestricModal} show={value.show} changeTo={props.changeToEmbedCode} index={index} remove={this.removeItem} duplicate={this.duplicateItem} ></PreEmbedCode>
            }

            if(value.type==="EmbedCode"){
                return <EmbedCode showRestricModal={this.props.showRestricModal} state={this.state} changeState={this.changeStateProps} update={this.updatePropsChild} show={value.show} content={value.content} remove={this.removeItem} index={index} duplicate={this.duplicateItem}></EmbedCode>
            }

            if(value.type==="Divider"){
                return <Separator showRestricModal={this.props.showRestricModal} state={this.state} update={this.updatePropsChild} show={value.show} content={value.content} remove={this.removeItem} index={index} duplicate={this.duplicateItem}></Separator>
            }

        });

        return Element;
    }

    renderTitle = (props) =>{
        return <Text  type={"Title"} focus={this.state.focus} state={this.state} changeState={this.changeStateProps} changeTitleInMain={this.changeTitle} content={props.state.name}></Text>
    }

    changeImagEditor = (value) =>{
        const sz = this.state.elements.length;
        var stateCopy = Object.assign({}, this.state);

        for(var i = 0; i<sz;i++){
            stateCopy.elements[i].focus=false;
        }

        this.setState(stateCopy);

        this.setState({background:value});
    }


    changePOSBG = (value) =>{

        const sz = this.state.elements.length;
        var stateCopy = Object.assign({}, this.state);
    
        for(var i = 0; i<sz;i++){
            stateCopy.elements[i].focus=false;
            stateCopy.posCover = value;

        }

        this.setState(stateCopy);

        this.setState({posCover:value});
        

        

    }

    selectEmoji = (emoji,event) =>{
        this.setState({emoji:emoji.id});
        this.setState({showemoji:false});
        this.props.updateEmoji(emoji.id,this.state.id);
    }

    showListEmoji = () =>{

        
        this.setState({showemoji:!this.state.showemoji});
    }

    hideEmoji = () => {
        
        this.setState({showemoji:false});
        this.setState({coverselect:false});
        
    }


    showCover = (opc) =>{
        this.setState({coverselect:opc});
    }


    hidenAll = () =>{
        const sz = this.state.elements.length;
        var stateCopy = Object.assign({}, this.state);
        for(var i = 0; i<sz;i++){
            stateCopy.elements[i].show=false;
        }
        this.setState(stateCopy);

    }

    updatePropsChild = (index) =>{
        const sz = this.state.elements.length;
        var stateCopy = Object.assign({}, this.state);
        stateCopy.elements[index].show=!stateCopy.elements[index].show;
        for(var i = 0; i<sz;i++){
            if(i!==index){
                stateCopy.elements[i].show=false;
            }
            stateCopy.elements[i].focus=false;
        }
        console.log('entro aqui');
        this.setState(stateCopy);
    }




    render(){


        return (

            <div className="container-editor">
                <Header showRestricModal={this.props.showRestricModal} profileImage={this.props.profileImage} showProfileModal={this.props.showProfileModal} page={localStorage.getItem('uid')+"/"+this.props.page}></Header>
                { (this.state.showemoji===true || this.state.coverselect===true) &&<div className="emoji-hover" onClick={ this.hideEmoji }>
                    </div>}
                <div className={this.state.coverselect===true?"header-editor topindex":"header-editor"}>    
                   <Cover  reposicion={this.state.coverselect}  showCover={this.showCover} changeBG={this.changeImagEditor} posBG={this.changePOSBG} content={this.state.background} posInicial={this.state.posCover}></Cover>
                </div>
                <div className="emoji-class">
    
                    <div className={this.state.coverselect===true?"emojo-selected topindex":"emojo-selected"} data-tip = "change emoji" >
                        <Emoji onClick={this.showListEmoji} emoji={{ id: this.state.emoji, skin: 3 }} size={64} />
                    </div>
                    <ReactTooltip place="right" type="dark" effect="solid"/>
                    { (this.state.showemoji===true) &&<div className="move-emo">
                        <Picker set='apple' onClick={this.selectEmoji} />
                    </div>}
                    
                </div>
            
                <div className="body-editor" onClick={this.handleParent.bind(this)} >
                    {this.renderTitle(this)}
                    {this.renderElements(this)}     
                </div>
            </div>
        );
    }

}





export default Editor;