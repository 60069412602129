import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'


class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
          file: '',
          imagePreviewUrl: '/img/Avatar.png',
          name: '',
          email: '',
          phone: ''
        };
        this._handleImageChange = this._handleImageChange.bind(this);
      }

      alertMessage = (type, message, goToHome) => {
        let buttons = []

        if(type == 'confirm'){
            buttons = [{
                  label: 'Yes',
                  onClick: () =>  this.updateProfile()
                },{
                  label: 'No',
                  onClick: () => {}
                }]
        } else if(type == 'deleteAccount'){
          buttons = [{
                label: 'Yes',
                onClick: () =>  this.deleteProfile()
              },{
                label: 'No',
                onClick: () => {}
              }]
        } else {
            if(goToHome){
              buttons = [{
                label: 'Ok',
                onClick: () => { 
                  window.location.replace(process.env.REACT_APP_BASE_URL + "home");
                }}]
            }else {
              buttons = [{
                label: 'Ok',
                onClick: () => { 
                }}]
            }
            
        }

        confirmAlert({
          title: 'Confirm',
          message: message,
          buttons: buttons
        })
      };
      alertDeleteAccount = () => {
        this.alertMessage('deleteAccount', 'Are you sure to delete your account?')
      }
      alertUpdateAccount = () => {
        this.alertMessage('confirm', 'Are you sure to update profile?')
      }
      updateProfile = () => {
        
        const data = new FormData() 
        if(this.state.file)
            data.append('file', this.state.file)
        data.append('name', this.state.name)
        //data.append('email', this.state.email)
        data.append('phone', this.state.phone)
        data.append('uid', localStorage.getItem('uid'))

        axios.post(process.env.REACT_APP_API_HOST+"updateProfile", data, { // receive two parameter endpoint url ,form data 
      
        })
        .then(res => { // then print response status

            if(res.statusText==="OK"){
                this.props.setProfileImage(res.data.profile_image)
                this.alertMessage('', 'The profile was updated successfully')
            }else{
              this.alertMessage('', 'An error occurred while updating the profile')
            }
        })
        .catch(res => {
          this.alertMessage('','An error occurred while updating the profile' )
        })
      }

      deleteProfile = () => {
        
        this.setState({confirm: false, deleteAccount: false})
        
        const data ={
          uid : localStorage.getItem('uid')
        }
        var user = firebase.auth().currentUser;

        user.delete().then(() => {
          axios.post(process.env.REACT_APP_API_HOST+"deleteProfile", data, { // receive two parameter endpoint url ,form data 
          })
          .then(res => { // then print response status
  
              if(res.statusText==="OK"){
                  this.props.setProfileImage(res.data.profile_image)
                  this.alertMessage('', 'The profile was deleted successfully', true)
              }else{
                this.alertMessage('', 'An error occurred while deleting the profile')
              }
          })
          .catch(err => {
            console.log(err)
            this.alertMessage('', 'An error occurred while deleting the profile')
          })
        }).catch((err) => {
          console.log(err)
          this.alertMessage('', 'An error occurred while deleting the profile')
        });
      }
    
      _handleImageChange(e) {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result
          });
        }
    
        reader.readAsDataURL(file)
      }

      onChangeName = (e) => {
        const value = e.target.value;
        this.setState({name:value});
      }

      onChangeEmail = (e) => {
        const value = e.target.value;
        this.setState({email:value});
      }

      onChangePhone = (e) => {
        const value = e.target.value;
        this.setState({phone:value});
      }

      componentDidMount() {
        const data ={
            uid : localStorage.getItem('uid')
        }
        axios.post(process.env.REACT_APP_API_HOST+'getClient',data)
        .then(client => {
            if(client.data){
                this.setState({
                    name: client.data.name,
                    email: client.data.email,
                    phone: client.data.phone,
                })

                if(client.data.profile_image){
                    this.setState({imagePreviewUrl: process.env.REACT_APP_API_HOST + client.data.profile_image})
                }
            }
        })

      }

    render() {
        let {imagePreviewUrl} = this.state;
      return (
        <div className="profile-details">
            <div className="form">
                <h2>Porfile details</h2>
                <div className="content-data">
                    <img src={imagePreviewUrl} />
                <h3>Image Profile</h3>
                <div className="file-upload"> 
                  <div className="file-upload-btn">
                    <button>select file</button>
                    <span>No file selected</span>
                  </div>
                  <input type="file" onChange={this._handleImageChange} />
                </div>
                <div class="embed-input">
                  <h3>Name</h3>
                  <input type="text" name="name" id="name" onChange={(e) => this.onChangeName(e)} placeholder="Name" value={this.state.name}/></div>
                <div class="embed-input">
                  <h3>Email</h3>
                  <input disabled type="text" name="email" id="email" onChange={(e) => this.onChangeEmail(e)} placeholder="Email" value={this.state.email}/></div>
                <div class="embed-input">
                  <h3>Phone</h3>
                  <input type="text" name="phone" id="phone" onChange={(e) => this.onChangePhone(e)} placeholder="Phone" value={this.state.phone} /></div>
                </div>
                <button type="submit" className="btn-update-profile" onClick={this.alertUpdateAccount} >Update profile</button>
                <button type="submit" className="btn-delete-profile" onClick={this.alertDeleteAccount} >Delete profile</button>
            </div>
        </div>
      );
    }
  } 

export default Profile;