
import React from 'react';
import {
    withRouter
} from 'react-router-dom';
import axios from 'axios';
class PaypalPartner extends React.Component{

    constructor(props){
        super(props);


    }


    componentDidMount = () => {

        let merchantId = this.props.match.params.merchantId;
        let merchantIdInPayPal = this.props.match.params.merchantIdInPayPal;

        console.log(merchantId+" "+merchantIdInPayPal);


    }

    render(){

        return(

            <h1>Has añadido correctamente tu cuenta de paypal</h1>
   
        );
    }


}

export default PaypalPartner;