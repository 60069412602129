import React from 'react';
class Image extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {

            show:false,
            showOption:false
        };
    }


    render(){
        return(
            <div className="element-editor">
                <div className="remove">
                </div>
                
                <div className="imagen-element">
                    <div className='imagen-body' style={{width:this.props.css+"%"}}>
                        <img src={process.env.REACT_APP_RESORUCE_URL+this.props.content} alt="img"></img>
                    </div>
                </div>
            </div>
        );
    }

}




export default Image;