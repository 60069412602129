import React from 'react';
import ListElementWithText from './ListElementWithText';
import { mdiDotsVertical , mdiPlus} from '@mdi/js';
import Icon from '@mdi/react';
class PreEmbedCode extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {

            value:"",
            show:false,
            showopc:false,
        };
    }

    onClickHandler = (event) => {
        
        

        //this.props.changeTo(name,this.props.index);

    }

    onChangeText = (event) => {
    
        const name = event.target.value;

        this.setState({value:name});

    }

    clickED = (event) =>{
        event.stopPropagation();
    }

    sendInfo = (event) => {
        event.stopPropagation();
        const value = this.state.value;
        this.props.changeTo(value,this.props.index);
    }

    componentWillReceiveProps = () =>{
        if(this.state.showOption===true){
            this.setState({showOption:false});
        }
    }

    showIcon() {
        this.setState({show:true});
    };
    
      hideIcon() {
        this.setState({show:false});
        this.setState({showplus:false});
        this.setState({showopc:false});
    };

    showOption = (e) =>{
        e.stopPropagation();
        this.setState({showopc:!this.state.showopc});
    }


    removeItem = (e) =>{
        e.stopPropagation();
        this.setState({showOption:false});
        this.props.remove(this.props.index);
    }

    duplicateItem = (e) =>{
        e.stopPropagation();
        this.setState({showOption:false});
        this.props.duplicate(this.props.index);
    }

    selectItem = (value,index) => {

        console.log(index);

        var stateCopy = Object.assign({}, this.props.state);
        if(value.name==="Image"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreImage";
        }
        if(value.name==="Header"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = true;
            stateCopy.elements[index].type = "Header";
        }
        if(value.name==="Subheader"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = true;
            stateCopy.elements[index].type = "SubHeader";
        }

        if(value.name==="Text"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = true;
            stateCopy.elements[index].type = "Text";
        }

        if(value.name==="File"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreFile";
        }

        if(value.name==="Google Drive"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreGoogleDocs";
        }

        if(value.name==="Youtube"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreYoutube";
        }

        if(value.name==="Record video"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedVideo";
            stateCopy.elements[index].subtype = "Micro";
        }
        if(value.name==="Record Video + Cam"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedVideo";
            stateCopy.elements[index].subtype = "Castcam";
        }

        if(value.name==="Embed Link" || value.name==="Typeform"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedLink";
        }

        if(value.name==="Divider"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "Divider";
        }

        if(value.name==="Video"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreFileVideo";
        }

        if(value.name==="Embed Code"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedCode";
        }

        this.props.changeState(stateCopy);
    }

    hidenElement = () =>{
        this.setState({showplus:false});
    }

    showOptionPlus = (e) =>{

        var x = this.offset(e.target);
        console.log(x);
        e.stopPropagation();

  

        if(x.top>=320){
            this.setState({clases:"top"});
        }else{
            this.setState({clases:"bottom"});
        }

        this.setState({showplus:!this.state.showplus});

    }

    offset(el) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }



    render(){
        return(
            <div className="element-editor" onMouseEnter={ this.showIcon.bind(this) }
            onMouseLeave={ this.hideIcon.bind(this) }>
                <div className="remove" style={{visibility: this.state.show ? 'visible' : 'hidden' }}>
                    <Icon path={mdiPlus} onClick={this.showOptionPlus}/>
                    <Icon path={mdiDotsVertical} onClick={this.showOption}/>
                    <div className="list-option" style={{visibility: this.state.showopc ? 'visible' : 'hidden' }}>
                        <ul>
                            <li onClick={this.removeItem}>  <img src={require('../Assets/trash.png')} alt="img"></img><span>Delete </span></li>
                            <li onClick={this.duplicateItem}><img src={require('../Assets/copy.png')} alt="img"></img> <span>Duplicate</span></li>
                        </ul>
                    </div>
                    {this.state.showplus?<ListElementWithText showRestricModal={this.props.showRestricModal} className={this.state.clases}  hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElementWithText>:<div></div>}
                </div>
                <div className="upload-image">
                    <div className="upload-body">
                        <ul>
                            <li onClick={this.changeEmbed}>Embed Code</li>
                        </ul>

                        <div className="embed-link">
                            <div className="embed-input">
                                <input type="text" name="embed" id="embed" onClick={this.clickED} onChange={this.onChangeText}  placeholder="Paste Code Here..." />
                            </div>
                            <div className="embed-btn">
                                <button className="btn" onClick={this.sendInfo}>Embed Code</button>
                            </div>
                                
                        </div>
                    
                    </div>

                </div>
            </div>
            
        );
    }

}




export default PreEmbedCode;