import React from 'react';
import axios from 'axios';
import RecordRTCPromisesHandler from 'recordrtc';
import Preload from './Preload';
import { mdiPause , mdiClose  } from '@mdi/js';
import Icon from '@mdi/react';

/*global chrome*/
class PreEmbedVideo extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {


            modal : false,
            cont : 3,
            counting:false,
            time:"10:00",
            showTime:false,


        };

        let Hi = null;

        let stream = null;
        let recorder = null;

        
    }


    componentDidMount() {

        console.log(this.props.type);

        if(this.props.type==='Castcam'){
            this.startCaptureCam(this.props,this);
        }else{
            if(this.props.type==='Micro'){
                this.startCaptureScreenWithMicro(this.props,this);
            }
        }

        
    }

    
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    async delayedGreeting() {
        await this.sleep(1000);
        this.setState({cont:this.state.cont-1});
        await this.sleep(1000);
        this.setState({cont:this.state.cont-1});
        await this.sleep(1000);
        this.setState({counting:false});
        await this.sleep(200);
        this.setState({cont:3});
      }


    converTime(cont,time_res){

        let second = (time_res-cont)%60;
        
        let minutes = ((time_res-cont) - (time_res-cont)%60)/60;


        let sminutes = minutes.toString();

        let ssecond = second.toString(); 

        sminutes = sminutes.length===1?"0"+sminutes:sminutes;
        ssecond  = ssecond.length===1?"0"+ssecond:ssecond;



        return sminutes+":"+ssecond;

    }

    async  startCaptureCam (props,ev) {

        
        let captureStream = null;
      
        try {  
            var appnotinstall = 1;
            var extensionId = process.env.REACT_APP_EXTENSION_ID;
            chrome.runtime.sendMessage(extensionId, {getSelectedContent: "true"},
             async response => {
                try{
                console.info("----------------- Got response", response);
                if(response===undefined){
                    ev.setState({modal:true});
                }
                if(response) {

                    let videoSteam =  await navigator.mediaDevices.getDisplayMedia({audio:false,video:true});        
            
                    let audiostream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true }); 
        
                    const tracks = [
                        ...videoSteam.getVideoTracks(),
                        ...audiostream.getAudioTracks()
                    ];


                    this.setState({counting:true});

                    /*setInterval(() =>  {
                        if(this.state.cont===3){
                            this.setState({counting:false});
                            this.setState({cont:0});
                            return;
                        }
                        this.setState({cont:this.state.cont+1});
                    }, 1000);*/

                    this.delayedGreeting().then(() => {

                        ev.stream = new MediaStream(tracks);
            
                        ev.recorder = new RecordRTCPromisesHandler(ev.stream, {
                            type: 'video',
                            mimeType: 'video/webm'
                        });
                        
                        ev.recorder.startRecording();

                        let cont = 0;

                        this.setState({
                            showTime:true,
                            time:"10:00"

                        });

                        setInterval(() => {
                            cont = cont + 1;
                            let Vtime = this.converTime(cont,10*60);
                            this.setState({
                                time:Vtime,
                            });
                        }, 1000);
                                    
                        ev.stream.getVideoTracks()[0].onended = async function () {

                            ev.stream.getTracks().forEach( track => track.stop());
            
                            chrome.runtime.sendMessage(extensionId, {getSelectedContent: "false"},
                            response => {
                                console.info("----------------- Got response", response);
                                if(response) {
                                        console.log("hola mundo");
                                }
                            });
                            
                            await ev.recorder.stopRecording(function() {

                                    ev.setState({
                                        showTime:false,
                                        time:"10:00"
            
                                    });
                            
                                
                                    var blob = this.getBlob();
                                    const data = new FormData() 
                                    var fileType = 'video';
                                    var fileName = localStorage.getItem('uid')+"-"+Math.floor((Math.random() * 1000000000) + 1) +".webm";
            
                                    data.append(fileType + '-filename', blob , fileName);
            
                                    ev.setState({preload:true});
            
                                    axios.post(process.env.REACT_APP_API_HOST+"api/test", data, { // receive two parameter endpoint url ,form data 
            
                                    }).then(res => { // then print response status
                                        console.log(res)
            
                                        if(res.statusText==="OK"){
                                            const name = localStorage.getItem('uid')+"/"+res.data.originalname;
                                            props.changeTo(name,props.index,props.currentPage);
                                        }
                                    })
                            });
                        };  

                    });        
        
                        console.log(response);
                
                }

            
             }catch(err){

                chrome.runtime.sendMessage(extensionId, {getSelectedContent: "false"},
                response => {
                    console.info("----------------- Got response", response);
                    if(response) {
                            console.log(response);
                    }
                });
                console.error("Error: " + err);

             }
            
            });
          
        } catch(err) {
           chrome.runtime.sendMessage(extensionId, {getSelectedContent: "false"},
                response => {
                    console.info("----------------- Got response", response);
                    if(response) {
                            console.log(response);
                    }
                });
          console.error("Error: " + err);
        }
        return captureStream;
    }

    async  startCaptureScreenWithMicro (props,ev) {

        console.log(props);
        
        let captureStream = null;
        let blobs = [];
      
        try {  
    
            let videoSteam =  await navigator.mediaDevices.getDisplayMedia({audio:false,video:true});

            let audiostream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });           

            const tracks = [
                ...videoSteam.getVideoTracks(),
                ...audiostream.getAudioTracks()
            ];  
            
            
            const data ={
                uid : localStorage.getItem('uid')
            }
            axios.post(process.env.REACT_APP_API_HOST+'restrictions',data)
            .then(restrictions => {

                let time_record = 10;

                if(restrictions.data){
                    time_record = parseInt(restrictions.data.record_duration);

                    this.setState({
                        showTime:true,
                        time:"02:00"
        
                    });
                }else{

                    this.setState({
                        showTime:true,
                        time:"10:00"
                    });

                }


                this.setState({counting:true});
            
                this.delayedGreeting().then(() => {
    
                ev.stream = new MediaStream(tracks);
    
                ev.recorder = new RecordRTCPromisesHandler(this.stream, {
                    type: 'video',
                    mimeType: 'video/webm',
                    ondataavailable: function(blob) {
                        blobs.push(blob);
                    }
                });
       
    
                ev.recorder.startRecording();
                let cont = 0;
                setInterval(() => {
                    cont = cont + 1;
                    let Vtime = this.converTime(cont,time_record*60);
                    this.setState({
                        time:Vtime,
                    });
                }, 1000);

                ev.stream.getVideoTracks()[0].onended = async function () {

                    ev.setState({
                        showTime:false,
                        time:"10:00"
                    });

                    ev.stream.getTracks().forEach( track => track.stop());
                    await ev.recorder.stopRecording(function() {
                        
                        var blob = this.getBlob();
                        const data = new FormData() 
                        var fileType = 'video';
                        var fileName = localStorage.getItem('uid')+"-"+Math.floor((Math.random() * 1000000000) + 1) +".webm";
            
                        data.append(fileType + '-filename', blob , fileName);
            
                        ev.setState({preload:true});
            
                        axios.post(process.env.REACT_APP_API_HOST+"api/test", data, { // receive two parameter endpoint url ,form data 
            
                        }).then(res => { // then print response status
                            console.log(res);

            
                            if(res.statusText==="OK"){
                                const name = localStorage.getItem('uid')+"/"+res.data.originalname;
                                props.changeTo(name,props.index,props.currentPage);
                            }
                        })
                    });

                }

                setTimeout(async function(){

                    ev.setState({
                        showTime:false,
                        time:"10:00"
                    });

                    ev.stream.getVideoTracks()[0].stop();
                    ev.stream.getTracks().forEach( track => track.stop());
                    await ev.recorder.stopRecording(function() {
                        
                        var blob = this.getBlob();
                        const data = new FormData() 
                        var fileType = 'video';
                        var fileName = localStorage.getItem('uid')+"-"+Math.floor((Math.random() * 1000000000) + 1) +".webm";
            
                        data.append(fileType + '-filename', blob , fileName);
            
                        ev.setState({preload:true});
            
                        axios.post(process.env.REACT_APP_API_HOST+"api/test", data, { // receive two parameter endpoint url ,form data 
            
                        }).then(res => { // then print response status
                            console.log(res);

            
                            if(res.statusText==="OK"){
                                const name = localStorage.getItem('uid')+"/"+res.data.originalname;
                                props.changeTo(name,props.index,props.currentPage);
                            }
                        })
                    });
                }, parseInt(time_record)* 60000);
    
                });
                
            })
                   
        } catch(err) {
          console.error("Error: " + err);
        }
        return captureStream;
    }

    closeModal = () =>{
        this.setState({modal:false});
    }



    stopVideo = async () =>{

        var extensionId = process.env.REACT_APP_EXTENSION_ID;

        this.setState({
            showTime:false,
            time:"10:00"

        });

        this.stream.getVideoTracks()[0].stop();

        this.stream.getTracks().forEach( track => track.stop());

        chrome.runtime.sendMessage(extensionId, {getSelectedContent: "false"},
        response => {
            console.info("----------------- Got response", response);
            if(response) {
                    console.log("hola mundo");
            }
        });


        let ev = this;
        await this.recorder.stopRecording(function() {
                
            var blob = this.getBlob();
            const data = new FormData() 
            var fileType = 'video';
            var fileName = localStorage.getItem('uid')+"-"+Math.floor((Math.random() * 1000000000) + 1) +".webm";

            data.append(fileType + '-filename', blob , fileName);

            ev.setState({preload:true});

            axios.post(process.env.REACT_APP_API_HOST+"api/test", data, { // receive two parameter endpoint url ,form data 

            }).then(res => { // then print response status
                console.log(res)

                if(res.statusText==="OK"){
                    const name = localStorage.getItem('uid')+"/"+res.data.originalname;
                    ev.props.changeTo(name,ev.props.index,ev.props.currentPage);
                }
            })
            });


    }

    cancelVideo = () =>{

        var extensionId = process.env.REACT_APP_EXTENSION_ID;

        this.stream.getVideoTracks()[0].stop();
        this.stream.getTracks().forEach( track => track.stop());

        this.setState({
            showTime:false,
            time:"10:00"
        });

        chrome.runtime.sendMessage(extensionId, {getSelectedContent: "false"},
        response => {
            console.info("----------------- Got response", response);
            if(response) {
                    console.log("hola mundo");
            }
        });
    }


    render(){
        return(

           <div>
               {this.state.showTime===true?(<div class="time-record" onClick={(e) => { e.stopPropagation()}}>
                    <div className="time">
                        <span>{this.state.time}</span>
                    </div>
                    <div className='opc-record'>
                        <Icon path={mdiPause} onClick={this.stopVideo}/>
                        <Icon path={mdiClose} onClick={this.cancelVideo}/>
                    </div>
               </div>):''}
                {(this.state.modal) && <div className="modal-need-app" onClick={this.closeModal}>
                    <div className="modal-content-need-app">
                        <span className="close"  onClick={this.closeModal}>X</span>
                        <span>Need to install our google chrome extension to use webcam <a target="_blank" href="https://chrome.google.com/webstore/detail/copack/geceheceigppknljieeenfghnlmoiobp"> Click here</a></span> 
                    </div>
                </div>}
                {(this.state.counting===true) &&
                
                    <div className="counting">
                            <span>{this.state.cont}</span>
                    </div>
                    
                }
                {(this.state.preload===true) && <Preload></Preload>}
           </div>

           

        )
    }

}




export default PreEmbedVideo;