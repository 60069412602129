import React from 'react';
class File extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            show:false
        };
    }


    truncate (str) {
        return str.length > 25 ? str.substring(0, 20) + "...pdf" : str;
    }





    render(){
        return(
            <div className="element-editor">
                <div className="remove">

                </div>
                <div className="element-file">

                    <a  download href={process.env.REACT_APP_RESORUCE_URL+this.props.content} className="file-uploaded">
                        <div className="icon-attachment">
                            <img src={require('../Assets/file.png')} alt="img"></img>
                        </div>
                        <div className="file-name">
                            {this.truncate(this.props.content.split("-")[1])}
                        </div>
                        <div className="file-size"> 
                            {this.props.size} KB
                        </div>
                    </a>
                
                </div>
            </div>
        );
    }

}




export default File;