import React from 'react';
import axios from 'axios';
import ListElement from './ListElement';
import ListElementWithText from './ListElementWithText';
import ContentEditable from "react-contenteditable";
import Icon from '@mdi/react'
import { mdiDotsVertical , mdiFileWordOutline, mdiPlus} from '@mdi/js';
import EditorTexto from './EditorTexto';

class Text extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            show:false,
            showOption:false,
            showplus:false,
            showopc:false,
            cont:0,
            showEditor:false,
            left:0,
            right:0,
            valSelected:"",
            selection:null
        };
    }

    componentDidMount(){
        if(this.nameInput!==undefined){
            if(this.nameInput!==null){

                var el = this.nameInput;
                el.focus();

            }
            
        }
    }

    componentDidUpdate(){
        if(this.nameInput!==undefined){
            if(this.nameInput!==null && this.props.focus===true){

                
                var el = this.nameInput; 

                var sz = el.innerText.replace(/(<([^>]+)>)/ig, '').length;

                var range = document.createRange();
                var sel = window.getSelection();

                if(el.childNodes[el.childNodes.length-1]===undefined) return;


                range.setStart(el.childNodes[el.childNodes.length-1], el.childNodes[el.childNodes.length-1].length);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
                el.focus();
              
            }
            
        }
    }


    showIcon() {
        this.setState({show:true});
    };


    saveSelection() {
        if (window.getSelection) {
            let sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                var ranges = [];
                for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                    ranges.push(sel.getRangeAt(i));
                }
                return ranges;
            }
        } else if (document.selection && document.selection.createRange) {
            return document.selection.createRange();
        }
        return null;
    }
    
    restoreSelection(savedSel) {
        if (savedSel) {
            if (window.getSelection) {
                let sel = window.getSelection();
                sel.removeAllRanges();
                for (var i = 0, len = savedSel.length; i < len; ++i) {
                    sel.addRange(savedSel[i]);
                }
            } else if (document.selection && savedSel.select) {
                savedSel.select();
            }
        }

    }


    getSelected(e){


        this.setState({
            selection:this.saveSelection()
        })


        var html = "";
        if (typeof window.getSelection != "undefined") {
            var sel = window.getSelection();
            if (sel.rangeCount) {
                var container = document.createElement("div");
                for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                    container.appendChild(sel.getRangeAt(i).cloneContents());
                }
                html = container.innerHTML;
            }
        } else if (typeof document.selection != "undefined") {
            if (document.selection.type == "Text") {
                html = document.selection.createRange().htmlText;
            }
        }


        var div = document.createElement("div");

        div.innerHTML = html;

        var text = div.textContent || div.innerText || "";

        if(text.length>0){
            this.setState({
                showEditor:true,
                texto:text
            })
        }

     

        //return t;
    }

    hideEditorText(){
        this.setState({
            showEditor:false
        })
    }
    
    hideIcon() {
        this.setState({show:false});
        this.setState({showplus:false});
        this.setState({showopc:false});
    };

    showOption = (e) =>{
        e.stopPropagation();
        this.setState({showopc:!this.state.showopc});
    }

    removeItem = (e) =>{
        e.stopPropagation();
        this.setState({showOption:false});
        this.props.remove(this.props.index);
    }

    duplicateItem = (e) =>{
        e.stopPropagation();
        this.setState({showOption:false});
        this.props.duplicate(this.props.index);
    }

    handleChangeText = (evt) => {
        const index = this.props.index;
        const value = evt.target.value.replace(/&nbsp;/g,' ');
        const sz = this.props.state.elements.length;
        var stateCopy = Object.assign({}, this.props.state);

        for(var i = 0; i<sz;i++){
            stateCopy.elements[i].focus=false;
        }

        if(value==='/'){          
            stateCopy.elements[index].content = value;
            stateCopy.elements[index].showelement = true;
            stateCopy.elements[index].focus = true;
        }else{
            stateCopy.elements[index].content = value;
            stateCopy.elements[index].showelement = false;
        }
        this.props.changeState(stateCopy);
    }


    getCaretCharacterOffsetWithin(element) {
        var caretOffset = 0;
        if (typeof window.getSelection != "undefined") {
            var range = window.getSelection().getRangeAt(0);
            var preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            caretOffset = preCaretRange.toString().length;
        } else if (typeof document.selection != "undefined" && document.selection.type != "Control") {
            var textRange = document.selection.createRange();
            var preCaretTextRange = document.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint("EndToEnd", textRange);
            caretOffset = preCaretTextRange.text.length;
        }
        return caretOffset;
    }


    handleKeyPressUp(e,special=null){

        const position = this.getCaretCharacterOffsetWithin(e.target);

        const index = this.props.index;



        if(e.keyCode===8){

            if(special!==null) return;
            

            if(position===0 && this.state.cont>0){


                var stateCopy = Object.assign({}, this.props.state);
                const content = this.props.state.elements[index].content;
                var pos = 0;
                var dx = 0;

                var found = false;
                    for(var i = index-1; i>=0; i--){
                        if(this.props.state.elements[i].type==="Text" || this.props.state.elements[i].type==="Header" || this.props.state.elements[i].type==="SubHeader"  ){
                            stateCopy.elements[i].focus=true;
                            stateCopy.elements[i].content = stateCopy.elements[i].content+  stateCopy.elements[index].content;
                            found = true;
                            pos = stateCopy.elements[i].content.length;
                            dx = i;
                            break; 
                        } 
                    }
                    if(found){
                        stateCopy.elements.splice(index,1);
                    }else{
                        // change the text of position becouse dont exit another text
                    }                
                
                this.props.changeState(stateCopy);
            }

            if(position===0){
                this.setState({cont:this.state.cont+1});
            }

        }
    }



    handleKeyPress (e,special=null) {

        const index = this.props.index;

        const position = this.getCaretCharacterOffsetWithin(e.target);

        if(e.key === 'Enter' && e.shiftKey){
            console.log('enter press here! ')
        }else{
            if(e.key === 'Enter'){
                const sz = this.props.state.elements.length;
                this.props.state.focus = false;
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = false;
                var stateCopy = Object.assign({}, this.props.state);
                var newval = ''; 

                if(special!==null){
                    stateCopy.elements.splice(0,0,{type:"Text",content:'',focus:true});
                    this.props.changeState(stateCopy);
                }else{
                    this.handleKeyPressAction(stateCopy, newval, index, position, sz);
                    /*const data ={
                        uid : localStorage.getItem('uid')
                    }
                    axios.post(process.env.REACT_APP_API_HOST+'restrictions',data)
                    .then(restrictions => {
                        if(!restrictions.data){
                            console.log('Entre4');
                           
                        }else{
                            console.log('restrictions', restrictions)
                            axios.post(process.env.REACT_APP_API_HOST+'usage',data)
                            .then(usage => {
                                let showModalRestriction = false
                                if(usage.data.lines >= restrictions.data.total_lines ){
                                    showModalRestriction = true;
                                }else{

                                }
                                if(!showModalRestriction){
                                    this.handleKeyPressAction(stateCopy, newval, index, position, sz);
                                }else{
                                    this.props.showRestricModal(true);
                                }
                            })
                        }
                    })*/
                }
            }
        }

    }

    buildStructure(el){

        let arr = [];

        for(var i=0;i<el.childNodes.length;i++){
            
            let textOriginal = el.childNodes[i];
            let textInto = el.childNodes[i].textContent;

            console.log(textInto);

            let begin = 0;

            let end = 0;

            if(i>0){
                begin = arr[i-1].end+1;
                end = begin+textInto.length-1;
            }else{
                end = textInto.length-1;
            }

            arr.push({
                "textOriginal":textOriginal,
                "textInto":textInto,
                "begin":begin,
                "end":end
            })
        }

        return arr;

    }

    getElements(arr,pos){

        let textLeft = "";
        let textRight = "";

        let currentElement="";

        let nextElement = "";

        console.log(pos);

        let found = 0;

        for(var i=0;i<arr.length;i++){
            if(arr[i].begin<=pos && arr[i].end>=pos){

                found = 1;
               
                let x = arr[i].begin - arr[i].begin;
                let y = pos - arr[i].begin;
                for(var j=x;j<y;j++){
                    textLeft+=arr[i].textInto[j];
                }

                x = pos-arr[i].begin;

                for(var j=x;j<arr[i].textInto.length;j++){
                    textRight+=arr[i].textInto[j];
                }

                currentElement+=textLeft;

                nextElement=textRight;


                /*for(var j=0;j<i;j++){
                    if(arr[j].textOriginal.nodeType==3){
                        currentElement+=arr[j].textOriginal.wholeText;
                    }

                    if(arr[j].textOriginal.nodeType==1){
                        currentElement+=arr[j].textOriginal.outerHTML;
                    }
                }

                currentElement+=textLeft;

                for(var j=i+1;j<arr.length;j++){

                    if(arr[j].textOriginal.nodeType==3){
                        nextElement+=arr[j].textOriginal.wholeText;
                    }

                    if(arr[j].textOriginal.nodeType==1){
                        nextElement+=arr[j].textOriginal.outerHTML;
                    }

                }

                console.log(textRight);

                nextElement = textRight+nextElement;

                break;*/

            }else{
                if(!found){

                    if(arr[i].textOriginal.nodeType==3){
                        currentElement+=arr[i].textOriginal.wholeText;
                    }

                    if(arr[i].textOriginal.nodeType==1){
                        currentElement+=arr[i].textOriginal.outerHTML;
                    }
                    

                }else{

                    if(arr[i].textOriginal.nodeType==3){
                        nextElement+=arr[i].textOriginal.wholeText;
                    }

                    if(arr[i].textOriginal.nodeType==1){
                        nextElement+=arr[i].textOriginal.outerHTML;
                    }

                }
            }
        }

        /* 13  */


        return {currentElement,nextElement};

    }

    handleKeyPressAction(stateCopy, newval, index, position, sz) {
        console.log('entro en este otro '+position);
        //newval = stateCopy.elements[index].content.substring(position,stateCopy.elements[index].content.length);
      
        
        var div = document.createElement("div");

        div.innerHTML = stateCopy.elements[index].content;

        console.log(div.childNodes[1]);

        /* Paso 1 */

        let structure = this.buildStructure(div);

        console.log(structure);

        /* Paso 2 */ 

        let {currentElement,nextElement} = this.getElements(structure,position); 

        console.log(currentElement+"--> hola");



        stateCopy.elements[index].content = currentElement;

        stateCopy.elements.splice(index+1,0,{type:"Text",content:nextElement,focus:true});

        this.props.changeState(stateCopy);

        /*

        algoritmo 
        
        paso 1:

        Construir la structura

        [{

            textOriginal,
            textInto,
            begin,
            end,

        }]

        paso 2:

        Encontrar las posición del cursor

        paso 3:

        Asignas al elemento actual los nodos anteriores y parte del actual

        paso 4:

        Asignar al elemento nuevo parte del actual y los nodos siguiente


        
        hola 0-5
        <a href="" >mundo</a> 6-11 12
        como estas

        textOriginal
        textoSinFormato
        begin:0
        end:5

        if(posCursor>=begin y posCursor<=end) 

        devulve todo los nodos anteiores y parte del actual
        devuelve parte del actual y los nodos siguientes

        el primero ira al contenido y el siguiente ira a la siguiente linea

   
        
        */

        

        var cad = div.textContent || div.innerText || "";

        newval = this.getString(cad,position,cad.length);


        return;
        
        if(cad.match(/<(\w+)((?:\s+\w+(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*)\s*(\/?)>/)) {

            if(newval!==''){

                stateCopy.elements[index].content = this.getTextInHtml(cad,position,cad.length);
                console.log(stateCopy.elements[index].content);
            }

        }
        
        if(newval!==''){
            stateCopy.elements[index].content = stateCopy.elements[index].content.substring(0,position);
        }
        stateCopy.elements[sz-1].focus=false;
        stateCopy.elements.splice(index+1,0,{type:"Text",content:newval,focus:true});
        this.props.changeState(stateCopy);
    }

    getTextInHtml = (text,posI,posF) =>{

            var c = text.substring(9,9+posI);

            return c;
    }

    getString = (text,posI,posF) =>{

        var cad = '';

        console.log(text);

        console.log(posI)

        var div = document.createElement("div");

        div.innerHTML = text;

        console.log(div);



        if(text.match(/<(\w+)((?:\s+\w+(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*)\s*(\/?)>/)) {
            var c = '';
            for(var i = 9;; i++){
                if(text[i]==='"') break;
                c+=text[i];
            }
            console.log(c);
            if(c.length===posI){
                return '';
            }else{
                for(var i=posI;i<c.length;i++){
                    cad+=c[i];
                }

                return cad;
            }
        }

        if(posI===posF){
            return '';
        }else{
            for(var i=posI;i<posF;i++){
                cad+=text[i];
            }
        }
        
        return cad;
    }
        
    handleChild = (e)  => {
        e.stopPropagation();
    }

    selectItem = (value,index) => {
        var stateCopy = Object.assign({}, this.props.state);
        if(value.name==="Image"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreImage";
        }
        if(value.name==="Header"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = true;
            stateCopy.elements[index].type = "Header";
        }
        if(value.name==="Subheader"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = true;
            stateCopy.elements[index].type = "SubHeader";
        }

        if(value.name==="Text"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = true;
            stateCopy.elements[index].type = "Text";
        }

        if(value.name==="File"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreFile";
        }

        if(value.name==="Google Drive"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreGoogleDocs";
        }

        if(value.name==="Youtube"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreYoutube";
        }

        if(value.name==="Record video"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedVideo";
            stateCopy.elements[index].subtype = "Micro";
        }
        if(value.name==="Record Video + Cam"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedVideo";
            stateCopy.elements[index].subtype = "Castcam";
        }

        if(value.name==="Embed Link" || value.name==="Typeform"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedLink";
        }

        if(value.name==="Divider"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "Divider";
        }

        if(value.name==="Video"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreFileVideo";
        }

        if(value.name==="Embed Code"){
            stateCopy.elements[index].content = "";
            stateCopy.elements[index].showelement = false;
            stateCopy.elements[index].focus = false;
            stateCopy.elements[index].type = "PreEmbedCode";
        }



        this.props.changeState(stateCopy);
    }

    handleChangeTextTitle =  (evt) => {

        const value = evt.target.value;


        this.props.changeTitleInMain(value);
        

    }

    showOptionPlus = (e) =>{

        var x = this.offset(e.target);
        e.stopPropagation();

  

        if(x.top>=320){
            this.setState({clases:"top"});
        }else{
            this.setState({clases:"bottom"});
        }

        this.setState({showplus:!this.state.showplus});

    }

    hidenElement = () =>{
        this.setState({showplus:false});
    }

    offset(el) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    selectMyText(e,index){
        e.stopPropagation();
        console.log(index);
        //this.props.selectMyText(index);
    }

    showLink(e){
        e.stopPropagation();
        console.log("Hola mundo");
    }

    clickLink(){
        console.log("asd");
    }

    getUrlPositions(word,pos){

        let link = "";

        let subword = "";

        let lastindex = -1;

        let addLink = false;
        let addWord = false;

        for(var i=pos;i<word.length;i++){
            if(addLink){
                if(word[i]==" "){
                    addLink = false;
                    continue;
                }else{
                    link+=word[i];
                }  
            }

            if(addWord){
                if(word[i]+word[i+1]+word[i+2]+word[i+3]+word[i+4]+word[i+5]+word[i+6]=="</span>"){
                    lastindex = i+10;
                    break;
                }else{
                    subword+=word[i];
                }           
            }

            if(word[i]+word[i+1]+word[i+2]+word[i+3]+word[i+4]==="href="){
                addLink = true;
                i+=4;
            }

            if(word[i]+word[i+1]+word[i+2]+word[i+3]+word[i+4]+word[i+5]==="<span>"){
                addWord = true;
                i+=5;
            }

            
        }


        return {link,subword,lastindex}

      


    }


    addText(val){

        let res = val.split("://");

        if(!(res[0]=="https" || res[0]=="http")){

            val="http://"+val;

        }

        let word = this.state.texto;



        this.restoreSelection(this.state.selection);

        document.execCommand("insertHTML", false, '<a href="' + val + '" target="_blank" style="cursor:pointer;" onclick={window.open("'+val+'")}>' + word + '</a>');

        this.setState({
            showEditor:false,
        })

    }

    renderText = () =>{
        if(this.props.type==="Text"){
            if(this.props.focus===true){
                return <div className="element-editor text-line"  onMouseEnter={ this.showIcon.bind(this) }
                onMouseLeave={ this.hideIcon.bind(this)} onClick={(e) => this.selectMyText(e,this.props.index)}>
                        <div className="remove" style={{visibility: this.state.show ? 'visible' : 'hidden' }}> 
                            <Icon path={mdiPlus} onClick={this.showOptionPlus}/>
                            <Icon path={mdiDotsVertical} onClick={this.showOption}/>
                            <div className="list-option" style={{visibility: this.state.showopc ? 'visible' : 'hidden' }}>
                                <ul>
                                    <li onClick={this.removeItem}>  <img src={require('../Assets/trash.png')} alt="img"></img><span>Delete </span></li>
                                    <li onClick={this.duplicateItem}><img src={require('../Assets/copy.png')} alt="img"></img> <span>Duplicate</span></li>
                                </ul>
                            </div>

                            {this.state.showplus?<ListElementWithText showRestricModal={this.props.showRestricModal} className={this.state.clases}  hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElementWithText>:<div></div>}

                        </div>
                        <div className='text-b'>
                            <ContentEditable
                            html={this.props.content} // innerHTML of the editable div
                            disabled={false} // use true to disable edition
                            onChange={(e) => this.handleChangeText(e)} // handle innerHTML change
                            innerRef={(input) => { this.nameInput = input; }}
                            onClick={(e) => this.handleChild(e)} 
                            onKeyDown={(e) => this.handleKeyPress(e)}
                            onKeyUp={(e) => this.handleKeyPressUp(e)}
                            className={'text-element'}
                            id ={'removed'}
                            onSelect={(e) => this.getSelected(e)}
                        />
                        {this.state.showEditor===true?<div className='hide-text' onClick={this.hideEditorText.bind(this)}></div>:""}
                        {this.state.showEditor===true?<EditorTexto addText={this.addText.bind(this)}></EditorTexto>:""}
                       </div>
                       {this.props.showelement===true?<ListElement showRestricModal={this.props.showRestricModal} hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElement>:<div></div>}
                </div>
            }else{
                return <div className="element-editor text-line"  onMouseEnter={ this.showIcon.bind(this) }
                onMouseLeave={ this.hideIcon.bind(this) } onClick={(e) => this.selectMyText(e,this.props.index)}>                        
                        <div className="remove" style={{visibility: this.state.show ? 'visible' : 'hidden' }}>
                        <Icon path={mdiPlus} onClick={this.showOptionPlus}/>
                        <Icon path={mdiDotsVertical} onClick={this.showOption}/>
                        <div className="list-option" style={{visibility: this.state.showopc ? 'visible' : 'hidden' }}>
                            <ul>
                                <li onClick={this.removeItem}>  <img src={require('../Assets/trash.png')} alt="img"></img><span>Delete </span></li>
                                <li onClick={this.duplicateItem}><img src={require('../Assets/copy.png')} alt="img"></img> <span>Duplicate</span></li>
                            </ul>
                        </div>
                        {this.state.showplus?<ListElementWithText showRestricModal={this.props.showRestricModal} className={this.state.clases} hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElementWithText>:<div></div>}

                    </div>
                    <div className='text-b'>    
                        <ContentEditable
                        html={this.props.content} // innerHTML of the editable div
                        disabled={false} // use true to disable edition
                        onChange={(e) => this.handleChangeText(e)} // handle innerHTML change
                        onClick={(e) => this.handleChild(e)} 
                        onKeyDown={(e) => this.handleKeyPress(e)}
                        onKeyUp={(e) => this.handleKeyPressUp(e)}
                        className={'text-element'}
                        id ={'removed'}
                        onSelect={(e) => this.getSelected(e)}
                        />
                        {this.state.showEditor===true?<div className='hide-text' onClick={this.hideEditorText.bind(this)}></div>:""}
                        {this.state.showEditor===true?<EditorTexto addText={this.addText.bind(this)} onClick={(e) => this.showLink.bind(this)}></EditorTexto>:""}
                    </div>
                    {this.props.showelement===true?<ListElement showRestricModal={this.props.showRestricModal} hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElement>:<div></div>}
              </div>
            }
        }
        if(this.props.type==="Header"){
            if(this.props.focus===true){
                return <div className="element-editor text-line"  onMouseEnter={ this.showIcon.bind(this) }
                onMouseLeave={ this.hideIcon.bind(this) }>
                        <div className="remove" style={{visibility: this.state.show ? 'visible' : 'hidden' }}>
                            <Icon path={mdiPlus} onClick={this.showOptionPlus}/>
                            <Icon path={mdiDotsVertical} onClick={this.showOption}/>
                            <div className="list-option" style={{visibility: this.state.showopc ? 'visible' : 'hidden' }}>
                                <ul>
                                    <li onClick={this.removeItem}>  <img src={require('../Assets/trash.png')} alt="img"></img><span>Delete </span></li>
                                    <li onClick={this.duplicateItem}><img src={require('../Assets/copy.png')} alt="img"></img> <span>Duplicate</span></li>
                                </ul>
                            </div>
                            {this.state.showplus?<ListElementWithText showRestricModal={this.props.showRestricModal} className={this.state.clases}  hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElementWithText>:<div></div>}

                        </div>
                        <div className='text-b'>
                        <ContentEditable
                        html={this.props.content} // innerHTML of the editable div
                        disabled={false} // use true to disable edition
                        onChange={(e) => this.handleChangeText(e)} // handle innerHTML change
                        innerRef={(input) => { this.nameInput = input; }}
                        onClick={(e) => this.handleChild(e)} 
                        onKeyDown={(e) => this.handleKeyPress(e)}               
                        onKeyUp={(e) => this.handleKeyPressUp(e)}
                        className={'header-element'}
                       />
                        {this.state.showEditor===true?<div className='hide-text' onClick={this.hideEditorText.bind(this)}></div>:""}
                        {this.state.showEditor===true?<EditorTexto addText={this.addText.bind(this)} onClick={(e) => this.showLink.bind(this)}></EditorTexto>:""}
                        </div>
                       
                       {this.props.showelement===true?<ListElement showRestricModal={this.props.showRestricModal} hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElement>:<div></div>}
                </div>
            }else{
                return <div className="element-editor text-line"  onMouseEnter={ this.showIcon.bind(this) }
                onMouseLeave={ this.hideIcon.bind(this) }>
                        <div className="remove" style={{visibility: this.state.show ? 'visible' : 'hidden' }}>
                            <Icon path={mdiPlus} onClick={this.showOptionPlus}/>
                            <Icon path={mdiDotsVertical} onClick={this.showOption}/>
                            <div className="list-option" style={{visibility: this.state.showopc ? 'visible' : 'hidden' }}>
                                <ul>
                                    <li onClick={this.removeItem}>  <img src={require('../Assets/trash.png')} alt="img"></img><span>Delete </span></li>
                                    <li onClick={this.duplicateItem}><img src={require('../Assets/copy.png')} alt="img"></img> <span>Duplicate</span></li>
                                </ul>
                            </div>
                            {this.state.showplus?<ListElementWithText showRestricModal={this.props.showRestricModal} className={this.state.clases}  hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElementWithText>:<div></div>}

                        </div>
                    <div className='text-b'>
                    <ContentEditable
                    html={this.props.content} // innerHTML of the editable div
                    disabled={false} // use true to disable edition
                    onChange={(e) => this.handleChangeText(e)} // handle innerHTML change
                    onClick={(e) => this.handleChild(e)} 
                    onKeyDown={(e) => this.handleKeyPress(e)}
                    onKeyUp={(e) => this.handleKeyPressUp(e)}
                    className={'header-element'}
                    />
                    {this.state.showEditor===true?<div className='hide-text' onClick={this.hideEditorText.bind(this)}></div>:""}
                    {this.state.showEditor===true?<EditorTexto addText={this.addText.bind(this)} onClick={(e) => this.showLink.bind(this)}></EditorTexto>:""}
                    
                    </div>
                    {this.props.showelement===true?<ListElement showRestricModal={this.props.showRestricModal} hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElement>:<div></div>}
              </div>
            }
        }
        if(this.props.type==="SubHeader"){
            if(this.props.focus===true){
                return <div className="element-editor text-line"  onMouseEnter={ this.showIcon.bind(this) }
                onMouseLeave={ this.hideIcon.bind(this) }>
                        <div className="remove" style={{visibility: this.state.show ? 'visible' : 'hidden' }}>
                            <Icon path={mdiPlus} onClick={this.showOptionPlus}/>
                            <Icon path={mdiDotsVertical} onClick={this.showOption}/>
                            <div className="list-option" style={{visibility: this.state.showopc ? 'visible' : 'hidden' }}>
                                <ul>
                                    <li onClick={this.removeItem}>  <img src={require('../Assets/trash.png')} alt="img"></img><span>Delete </span></li>
                                    <li onClick={this.duplicateItem}><img src={require('../Assets/copy.png')} alt="img"></img> <span>Duplicate</span></li>
                                </ul>
                            </div>
                            {this.state.showplus?<ListElementWithText showRestricModal={this.props.showRestricModal} className={this.state.clases}  hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElementWithText>:<div></div>}

                        </div>
                        <div className='text-b'>
                        <ContentEditable
                        html={this.props.content} // innerHTML of the editable div
                        disabled={false} // use true to disable edition
                        onChange={(e) => this.handleChangeText(e)} // handle innerHTML change
                        innerRef={(input) => { this.nameInput = input; }}
                        onClick={(e) => this.handleChild(e)} 
                        onKeyDown={(e) => this.handleKeyPress(e)}
                        onKeyUp={(e) => this.handleKeyPressUp(e)}
                        className={'subheader-element'}
                       />
                        {this.state.showEditor===true?<div className='hide-text' onClick={this.hideEditorText.bind(this)}></div>:""}
                        {this.state.showEditor===true?<EditorTexto addText={this.addText.bind(this)} onClick={(e) => this.showLink.bind(this)}></EditorTexto>:""}

                       </div>
                       {this.props.showelement===true?<ListElement showRestricModal={this.props.showRestricModal} hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElement>:<div></div>}
                </div>
            }else{
                return <div className="element-editor text-line"  onMouseEnter={ this.showIcon.bind(this) }
                onMouseLeave={ this.hideIcon.bind(this) }>
                    <div className="remove" style={{visibility: this.state.show ? 'visible' : 'hidden' }}>
                            <Icon path={mdiPlus} onClick={this.showOptionPlus}/>
                            <Icon path={mdiDotsVertical} onClick={this.showOption}/>
                            <div className="list-option" style={{visibility: this.state.showopc ? 'visible' : 'hidden' }}>
                                <ul>
                                    <li onClick={this.removeItem}>  <img src={require('../Assets/trash.png')} alt="img"></img><span>Delete </span></li>
                                    <li onClick={this.duplicateItem}><img src={require('../Assets/copy.png')} alt="img"></img> <span>Duplicate</span></li>
                                </ul>
                            </div>
                            {this.state.showplus?<ListElementWithText showRestricModal={this.props.showRestricModal} className={this.state.clases}  hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElementWithText>:<div></div>}

                    </div>
                    <div className='text-b'>
                    <ContentEditable
                    html={this.props.content} // innerHTML of the editable div
                    disabled={false} // use true to disable edition
                    onChange={(e) => this.handleChangeText(e)} // handle innerHTML change
                    onClick={(e) => this.handleChild(e)} 
                    onKeyDown={(e) => this.handleKeyPress(e)}
                    onKeyUp={(e) => this.handleKeyPressUp(e)}
                    className={'subheader-element'}
                    />
                    {this.state.showEditor===true?<div className='hide-text' onClick={this.hideEditorText.bind(this)}></div>:""}
                    {this.state.showEditor===true?<EditorTexto addText={this.addText.bind(this)} onClick={(e) => this.showLink.bind(this)}></EditorTexto>:""}
                    </div>
                    {this.props.showelement===true?<ListElement showRestricModal={this.props.showRestricModal} hidenElement={this.hidenElement} index={this.props.index} selectItemChild = {this.selectItem}></ListElement>:<div></div>}
              </div>
            }
        }

        if(this.props.type==="Title"){

            if(this.props.focus){
                return <div className="element-editor text-line">
                    <div>

                    </div>
                    <ContentEditable
                        html={this.props.content} // innerHTML of the editable div
                        disabled={false} // use true to disable edition
                        onChange={(e) => this.handleChangeTextTitle(e)} // handle innerHTML change
                        onClick={(e) => this.handleChild(e)} 
                        onKeyDown={(e) => this.handleKeyPress(e,1)}
                        className={'title-page'}
                        innerRef={(input) => { this.nameInput = input; }}
                        placeholder = {"Untitle"}
                    />

                </div>

            }else{
                return <div className="element-editor text-line">
                    <div>
                    </div>
                    <ContentEditable
                        html={this.props.content} // innerHTML of the editable div
                        disabled={false} // use true to disable edition
                        onChange={(e) => this.handleChangeTextTitle(e)} // handle innerHTML change
                        onClick={(e) => this.handleChild(e)} 
                        onKeyDown={(e) => this.handleKeyPress(e,1)}
                        className={'title-page'}
                        placeholder = {"Untitle"}
                    />

                </div>

            }

        }
    }


    render(){

        return( 
           this.renderText()
        )

    }

}




export default Text;