import React from 'react';
import ContentEditable from "react-contenteditable";

class Text extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            show:false,
            showOption:false
        };
    }


    renderText = () =>{
        if(this.props.type==="Text"){
            if(this.props.focus===true){
                return <div className="element-editor text-line">
                        <div className="remove">
                        </div>
                        <ContentEditable
                        html={this.props.content} // innerHTML of the editable div
                        disabled={true} // use true to disable edition
                        className={'text-element'}
                       />
                </div>
            }else{
                return <div className="element-editor text-line">                        
                        <div className="remove">
                        </div>    
                    <ContentEditable
                    html={this.props.content} // innerHTML of the editable div
                    disabled={true} // use true to disable edition
                    className={'text-element'}
                    />
              </div>
            }
        }
        if(this.props.type==="Header"){
            if(this.props.focus===true){
                return <div className="element-editor text-line">
                        <div className="remove">
                        </div>
                        <ContentEditable
                        html={this.props.content} // innerHTML of the editable div
                        disabled={true} // use true to disable edition
                        className={'header-element'}
                       />
                </div>
            }else{
                return <div className="element-editor text-line">
                        <div className="remove">
                        </div>
                    <ContentEditable
                    html={this.props.content} // innerHTML of the editable div
                    disabled={true} // use true to disable edition
                    className={'header-element'}
                    />
              </div>
            }
        }
        if(this.props.type==="SubHeader"){
            if(this.props.focus===true){
                return <div className="element-editor text-line">
                        <div className="remove">               
                        </div>
                        <ContentEditable
                        html={this.props.content} // innerHTML of the editable div
                        disabled={true} // use true to disable edition
                        className={'subheader-element'}
                       />
                </div>
            }else{
                return <div className="element-editor text-line">
                    <div className="remove">
                    </div>
                    <ContentEditable
                    html={this.props.content} // innerHTML of the editable div
                    disabled={true} // use true to disable edition
                    className={'subheader-element'}
                    />
              </div>
            }
        }

        if(this.props.type==="Title"){
            return <div className="element-editor text-line">
                <div></div>
                <ContentEditable
                    html={this.props.content} // innerHTML of the editable div
                    disabled={true} // use true to disable edition
                    className={'title-page'}
                />

            </div>

        }
    }

    


    render(){

        return( 
           this.renderText()
        )

    }

}




export default Text;