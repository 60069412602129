import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'


class Subscription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: '',
            message: 'Are you sure to cancel subscription?',
            confirm: true
        };
      }
    
      cancelSubscription = () => {
        this.setState({confirm: false})
        const data ={
            uid : localStorage.getItem('uid')
        }
        axios.post(process.env.REACT_APP_API_HOST+"stripe/cancelSubscription", data)
        .then(res => { // then print response status
            console.log(res)

            if(res.statusText==="OK"){
              this.setState({message: 'The subscription was canceled successfully'})
              this.alertMessage()
              this.props.cancelSubscription()
            } else {
              console.log(res)
              this.setState({message: 'An error occurred while canceling the subscription'})
              this.alertMessage()
            }
        })
        .catch(res => {
          this.setState({message: 'An error occurred while canceling the subscription'})
          this.alertMessage()
        })
      }

      alertMessage = () => {
        let buttons = []

        if(this.state.confirm){
            buttons = [
                {
                  label: 'Yes',
                  onClick: () =>  this.cancelSubscription()
                },
                {
                  label: 'No',
                  onClick: () => {}
                }
              ]
        } else {
            buttons = [
                {
                  label: 'Ok',
                  onClick: () => { 
                      this.setState({confirm: true, message: 'Are you sure to cancel subscription?'})
                     }
                }
              ]
        }
        confirmAlert({
          title: 'Confirm',
          message: this.state.message,
          buttons: buttons
        })
      };

      componentDidMount() {
        const data ={
            uid : localStorage.getItem('uid')
        }
        axios.post(process.env.REACT_APP_API_HOST+'getClient',data)
        .then(client => {
            if(client.data){
                this.setState({
                    date: client.data.date_payment
                })
            }
        })
      }

    render() {
      return (
        <div className="profile-details">
          <h2>Subscription details</h2>
          <div className="content-data-subscription">
            <label>Your Copack Premium subscription expires on {moment(this.state.date).format('YYYY-DD-MM')} remember to make the payment to continue enjoying the service</label>
          </div>
          <button type="submit" className="btn-cancel-subscription" onClick={this.alertMessage} >Cancel subscription</button>
        </div>
      );
    }
  } 

export default Subscription;