import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';




class VerifyUser extends Component {

  constructor(props){
    super(props); 

    this.state = {
      error:false
    };
  }



  showElement = () =>{
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo = params.get('oobCode');


    try {
      firebase
      .auth().applyActionCode(foo).then(function(resp) {
          firebase
          .auth().reload();
        }).catch(function(error) {
          console.log(error);
        });

        return true;
      
    } catch (error) {
      
      return false

    }
    
   

  }


    render() {

     

      return (
        <div>
          {(this.showElement()===true)?
          <div className='verify-user'>
              <img src={require("../Assets/check.png")}></img>
              <span>Your account was verify corretly...! <a href="/login">Click here to enter</a></span>
          </div>
          :
          <div className='verify-user'>
              <img src={require("../Assets/error.png")}></img>
              <span>Your account not was verify corretly...!</span>
          </div>
          }
        </div>
      );
    }
  } 

export default VerifyUser;