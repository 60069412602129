import React from 'react';
import { Player , ControlBar , PlaybackRateMenuButton , BigPlayButton  } from 'video-react';
class EmbedVideo extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            show:false
        };
    }



    render(){
        return(
            <div className="element-editor">
                <div className="remove">
                </div>
                <div className="video-element">
                <Player
                playsInline
                preload={'metadata'}
                src={process.env.REACT_APP_RESORUCE_URL+this.props.content.split(".")[0]+".mp4"}
                >
                <BigPlayButton position="center" />
                <ControlBar autoHide={false}>
                    <PlaybackRateMenuButton order={7} rates={[5, 2, 1, 0.5, 0.1]} />
                </ControlBar>
                </Player>
                </div>
            </div>
        );
    }

}




export default EmbedVideo;