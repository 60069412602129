import React from 'react';
import Image from './Image';
import EmbedVideo from './EmbedVideo';
import File from './File';
import Youtube from './Youtube';
import GoogleDocs from './GoogleDocs';
import EmbedLink from './EmbedLink'; 
import EmbedCode from './EmbedCode'; 
import Cover from './Cover';
import Text from './Text';
import Separator from './Separator'; 
import Video from './Video';
import axios from 'axios';
import 'emoji-mart/css/emoji-mart.css'
import { Emoji , Picker } from 'emoji-mart'
import ModalForm from './ModalForm';
class Editor extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
                background:"bg1.jpg",
                showemoji:false,
                elements:[]

        };




    }


    componentDidMount() {
        
        const data ={
            uid : this.props.uid,
            page: this.props.id_page
        }

        console.log(data);

        axios.post(process.env.REACT_APP_API_HOST+'getdata',data)
        .then(res => {
            this.setState(res.data);
            if(this.props.private===true){
                this.setState({
                    private:true
                })
            }
        })
    }

    componentDidUpdate(){

        const data ={
            uid : this.props.uid,
            page: this.props.id_page
        }

        axios.post(process.env.REACT_APP_API_HOST+'getdata',data)
        .then(res => {
            this.setState(res.data);
            if(this.props.private===true){
                this.setState({
                    private:true
                })
            }
        })
    }

    
    handleChild(e) {
        e.stopPropagation();    
        console.log("child");
    }


    changeStateProps = (value) =>{
        console.log(value);
        this.setState(value);
    }

    closeLoginModal = ()  => { 
        this.setState({private: false})
    }

    renderElements = (props) => {



        const Element = props.state.elements.map((value,index) => {

            if(value.type==="Text" || value.type==="Header" || value.type==="SubHeader"){
 

                return <Text content={value.content} type={value.type} state={this.state}></Text>
            }
            if(value.type==="Image"){
                return <Image content={value.content} css={value.css}></Image>
            }
            if(value.type==="EmbedVideo"){
                return <EmbedVideo content={value.content}></EmbedVideo>
            }

            if(value.type==="File"){
                return <File content={value.content} size={value.size}></File>
            }

            if(value.type==="Youtube"){
                return <Youtube content={value.content}></Youtube>
            }
            

            if(value.type==="GoogleDocs"){
                return <GoogleDocs content={value.content}></GoogleDocs>
            }

            if(value.type==="EmbedLink"){
                return <EmbedLink content={value.content} ></EmbedLink>
            }

            if(value.type==="EmbedCode"){
                return <EmbedCode content={value.content} ></EmbedCode>
            }

            if(value.type==="Divider"){
                return <Separator  content={value.content}></Separator>
            }

            if(value.type==="Video"){
                return <Video content={value.content}></Video>
            }

        });

        return Element;
    }

    renderTitle = (props) =>{
        console.log(props);
        return <Text  type={"Title"} state={this.state} changeState={this.changeStateProps} changeTitleInMain={this.changeTitle} content={props.state.name} focus={false}></Text>
    }

    changeImagEditor = (value) =>{
        const sz = this.state.elements.length;
        var stateCopy = Object.assign({}, this.state);

        for(var i = 0; i<sz;i++){
            stateCopy.elements[i].focus=false;
        }

        this.setState(stateCopy);

        this.setState({background:value});
    }



    render(){


        return (

            

            <div className="container-editor-shared">
                {this.state.private===true?<ModalForm closeLoginModal={this.closeLoginModal} page={this.props.page} pageId={this.state.id}></ModalForm>:""}
                <div className="header-editor">
                   <Cover content={this.state.background}></Cover>
                </div>
                <div className="emoji-class">
                    <div className="emojo-selected">
                        <Emoji  emoji={{ id: this.state.emoji, skin: 3 }} size={78} />
                    </div>
                    { (this.state.showemoji===true) &&<div className="move-emo">
                        <Picker set='apple' onClick={this.selectEmoji} />
                    </div>}
                 </div>
                <div className="body-editor" >
                    {this.renderTitle(this)}
                    {this.renderElements(this)}           
                </div>
                <div className="create-doc">
                    <a href={(localStorage.getItem('uid'))?'/' : '/home'}>Crear Docs</a>
                </div>
            </div>
        );
    }

}





export default Editor;