import React from 'react';
class Separator extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {

            show:false,
        };
    }



    render(){
        return(
            <div className="element-editor">
                <div className="remove">
                </div>
                
                <div className="separator-element">
                   <div className="separator"></div>
                </div>
            </div>
        );
    }

}




export default Separator;